import React from 'react';
import { Modal, Collapse, Tag } from 'antd';
import { isEmpty } from 'lodash';

import { ERROR_MESSAGES, ERROR_DESCRIPTION } from './constants';

const { Panel } = Collapse;

const getModalContent = errors => (
  <Collapse>
    {Object.keys(errors).map(error => (
      <Panel
        key={error}
        header={`${errors[error].length} ${ERROR_MESSAGES[error]}`}
      >
        <p>{ERROR_DESCRIPTION[error]}</p>
        <p>
          {errors[error].map((e, i) => (
            <Tag key={`${error}-${i}`}>{e}</Tag>
          ))}
        </p>
      </Panel>
    ))}
  </Collapse>
);

// this is not the final method, using this meanwhile
// to have some way to display resuts
const showValidationErrors = (errors, onOk = () => {}, onCancel = () => {}) => {
  const hasErrors = !isEmpty(errors);
  const title = 'Form validation results';
  const type = hasErrors ? 'confirm' : 'success';
  const content = hasErrors ? getModalContent(errors) : 'Success';

  // eslint-disable-next-line no-console
  console.log('Validation errors', errors);
  Modal[type]({
    title,
    content,
    onOk,
    onCancel,
    okText: hasErrors ? 'Apply Fix' : 'OK',
    cancelText: 'Do Not Apply Fix',
  });
};

export default showValidationErrors;
