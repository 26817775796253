import React, { Component } from 'react';
import { Button, Icon, Row, Col, Spin, notification } from 'antd';
import {
  pick,
  get,
  kebabCase,
  toPath,
  first,
  isEmpty,
  forEachRight,
} from 'lodash';
import { connect } from 'react-redux';

import storage from 'APP_ROOT/utils/storage';
import addAttachment from 'APP_ROOT/actions/add-attachment';
import updateAttachment from 'APP_ROOT/actions/update-attachment';
import removeAttachment from 'APP_ROOT/actions/remove-attachment';
import fetchFormAttachments from 'APP_ROOT/actions/fetch-form-attachments';
import deleteFormAttachment from 'APP_ROOT/actions/delete-form-attachment';
import addRepeaterItem from 'APP_ROOT/actions/add-repeater-item';
import syncFormData from 'APP_ROOT/actions/sync-form-data';
import { FORM_IDENTIFIERS } from 'APP_ROOT/constants/form-types';
import AttachmentsWarning from '../../../common/attachments-warning';
import onStateChange from 'APP_ROOT/actions/on-upload-state-change';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import { StyledLineDivide, StyledUpload } from './styled';
import FILE_STATUSES from './file-statuses.constants';
import getURL from './utils/getURL';
import uploadOverride from './utils/uploadOverride';
import onBeforeUpload from './utils/onBeforeUpload';
import saveDraft from './utils/saveDraft';
import isEditableStatus from './utils/isEditableStatus';
import setFileUploaded from './utils/setFileUploaded';
import renderAttachmentAsOwner from './utils/renderAttachmentAsOwner';
import renderAttachmentAsReviewer from './utils/renderAttachmentAsReviewer';

import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';
import { getFormTemplate, getDataEnums } from 'APP_ROOT/selectors/form';

import { getDataSelector } from 'APP_ROOT/utils/renderSchema';
import removeRepeaterItem from 'APP_ROOT/actions/remove-repeater-item';

import FileSizeLimit from '../../forms/utils/fileSizeLimit';

import getFieldIsDisabled from '../../object-types/FormField/getFieldIsDisabled';

const [DONE, ERROR, UPLOADING] = FILE_STATUSES;

export class InputUpload extends Component {
  state = {
    attachments: [],
    isMounted: false,
    uploaded: false,
    uploadingIds: [],
  };

  componentDidMount() {
    this.fetchAttachments(this.props);
  }

  componentDidUpdate(prevProps) {
    const { fileUploaded } = this.props;
    if (prevProps.fileUploaded !== fileUploaded && fileUploaded) {
      saveDraft(this.props);
    }
  }

  UNSAFE_componentWillUpdate(props) {
    const {
      dataKey,
      data: { [dataKey]: formAttachments = {} },
      meta: { id: nextId = '' } = {},
    } = props;

    const {
      data: { [dataKey]: oldFormAttachments = {} },
      meta: { id = '' } = {},
    } = this.props;

    if (
      (JSON.stringify(formAttachments) !== JSON.stringify(oldFormAttachments) &&
        this.isReviewer()) ||
      (nextId !== id && id)
    ) {
      this.fetchAttachments(props);
    }
  }

  updateFormValues = () => {
    const {
      dataKey,
      form: { setFieldsValue },
      settings,
    } = this.props;
    const payload = this.getPayload();

    const fieldsValues = payload.reduce(
      (attachments, attachment, index) => ({
        ...attachments,
        [`${dataKey}[${index}].${attachment.id}-notes`]:
          attachment.notes || attachment[`${attachment.id}-notes`] || '',
      }),
      {}
    );

    if (payload.length > 0 && !this.isReviewer()) {
      Object.keys(fieldsValues).forEach(field => {
        setFieldsValue({ [field]: fieldsValues[field] });
      });
    }

    emitFormEvent('onAttachmentsFetched', settings.formName);
  };

  removeBrokenReferences = () => {
    const { dispatch, dataKey, data } = this.props;
    const { attachments = [] } = this.state;
    const files = get(data, dataKey, []);
    forEachRight(files, (file, index) => {
      if (!attachments.some(a => a.id === file.id)) {
        dispatch(removeRepeaterItem(dataKey, index));
      }
    });
  };

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  getPayload = () => {
    const {
      form: { getFieldValue },
      dataKey,
    } = this.props;
    const { attachments } = this.state;

    return attachments.reduce(
      (newAttachments, attachment, index) =>
        attachment.status === DONE
          ? [
              ...newAttachments,
              {
                ...pick(attachment, ['id', 'notes', `${attachment.id}-notes`]),
                [`${attachment.id}-notes`]:
                  get(attachment, `${attachment.id}-notes`) ||
                  getFieldValue(`${dataKey}[${index}].${attachment.id}-notes`),
              },
            ]
          : newAttachments,
      []
    );
  };

  onRemoveFile = (attachment, index) => {
    const {
      form: { resetFields },
      dispatch,
      dataKey,
    } = this.props;
    const resetKey = `${dataKey}[${index}].${attachment.id}-notes`;

    if (attachment.status === UPLOADING) {
      this.uploader.handleManualRemove(attachment.originFileObj);
    } else {
      this.onRemoveFileOnStorage({ uid: attachment.id });
    }
    resetFields([resetKey]);
    dispatch(removeRepeaterItem(dataKey, index));
  };

  onRemoveFileOnStorage = object => {
    const { uid } = object;
    const {
      meta: { id = '', formType = '' } = {},
      dataKey,
      settings,
    } = this.props;
    const { attachments } = this.state;
    const filtered = attachments.filter(a => a.id === uid);
    const currentAttachment = filtered.length ? filtered[0] : null;

    const callback = () => {
      this.setState(
        {
          attachments: attachments.filter(attachment => attachment.id !== uid),
        },
        () => {
          this.onStateChange();
          saveDraft(this.props);
        }
      );
    };

    if (currentAttachment) {
      const actionParams = {
        kind: FORM_IDENTIFIERS[kebabCase(formType)],
        formId: id,
        field: dataKey,
        attachmentId: uid,
      };
      if (currentAttachment.status === DONE) {
        formActionDispatcher(
          settings.formName,
          deleteFormAttachment(actionParams, callback)
        );
      } else {
        formActionDispatcher(
          settings.formName,
          removeAttachment(actionParams, callback)
        );
      }
    }
  };

  saveAttachmentOnStorage = attachment => {
    const {
      meta: { id = '', formType = '' } = {},
      dataKey,
      settings,
    } = this.props;
    formActionDispatcher(
      settings.formName,
      addAttachment({
        kind: FORM_IDENTIFIERS[kebabCase(formType)],
        formId: id,
        field: dataKey,
        attachment,
      })
    );
  };

  updateAttachmentOnStorage = attachment => {
    const {
      meta: { id = '', formType = '' } = {},
      dataKey,
      settings,
    } = this.props;
    formActionDispatcher(
      settings.formName,
      updateAttachment({
        kind: FORM_IDENTIFIERS[kebabCase(formType)],
        formId: id,
        field: dataKey,
        attachment,
      })
    );
  };

  updateSessionAttachmentNotes = (key, notes) => {
    const dynamicKey = `${key}-notes`;

    this.setState(prevState => ({
      attachments: prevState.attachments.map(attachment =>
        attachment.id === key
          ? {
              ...attachment,
              notes,
              [dynamicKey]: notes,
            }
          : attachment
      ),
    }));
  };

  createDoneState = (file, fileExists) => {
    const {
      data: { activeUser },
    } = this.props;
    const { attachments, uploadingIds } = this.state;
    const { status, response = {}, originFileObj = {} } = file;

    return {
      uploaded: true,
      uploadingIds: uploadingIds.filter(id => id !== originFileObj.uid),
      attachments: fileExists
        ? attachments.map(attachment => {
            if (attachment.id === originFileObj.uid) {
              return {
                ...attachment,
                id: response.id,
                status,
              };
            }

            return attachment;
          })
        : [
            ...attachments,
            {
              id: response.id,
              status,
              progress: file.percent,
              name: file.name,
              originFileObj,
              ownerId: activeUser,
            },
          ],
    };
  };

  onChangeDoneState = (file, fileExists) => {
    const { dataKey, settings } = this.props;
    const { response = {} } = file;

    notification.success({
      message: 'Uploaded successfully!',
      description: `File: ${file.name}`,
    });
    this.setState(this.createDoneState(file, fileExists), () => {
      formActionDispatcher(
        settings.formName,
        addRepeaterItem(
          [dataKey],
          [],
          response.id,
          [],
          { notes: '' },
          {},
          {},
          true
        )
      );
      this.updateFormValues();
      this.saveAttachmentOnStorage(response);
      this.onStateChange();
    });
  };

  onChangeErrorState = (file, fileExists) => {
    const {
      data: { activeUser },
    } = this.props;
    const { attachments, uploadingIds } = this.state;
    const { status, originFileObj = {} } = file;

    const { error } = file.response || file;
    notification.error({
      message: `Uploaded "${file.name}" failed!`,
      description: error.message,
    });
    const newAttachment = {
      id: file.uid,
      status,
      error: file.error,
      name: file.name,
      ownerId: activeUser,
    };

    this.setState(
      {
        uploaded: true,
        uploadingIds: uploadingIds.filter(id => id !== originFileObj.uid),
        attachments: fileExists
          ? attachments.map(attachment => {
              if (attachment.id === originFileObj.uid) {
                return newAttachment;
              }

              return attachment;
            })
          : [...attachments, newAttachment],
      },
      () => {
        this.saveAttachmentOnStorage(newAttachment);
        this.onStateChange();
      }
    );
  };

  onChangeUploadingState = (file, fileExists) => {
    const {
      data: { activeUser },
    } = this.props;
    const { attachments, uploadingIds } = this.state;
    const { originFileObj = {} } = file;

    this.setState(
      {
        uploaded: false,
        uploadingIds: uploadingIds.includes(originFileObj.uid)
          ? uploadingIds
          : [...uploadingIds, originFileObj.uid],
        attachments: fileExists
          ? attachments.map(attachment => {
              if (attachment.id === originFileObj.uid) {
                return {
                  ...attachment,
                  id: originFileObj.uid,
                  status: file.status,
                  progress: file.percent,
                  name: file.name,
                  originFileObj,
                  ownerId: activeUser,
                };
              }

              return attachment;
            })
          : [
              ...attachments,
              {
                id: originFileObj.uid,
                status: file.status,
                progress: file.percent,
                name: file.name,
                originFileObj,
                ownerId: activeUser,
              },
            ],
      },
      () => {
        this.onStateChange();
        setFileUploaded(this.props);
      }
    );
  };

  onChange = ({ file = {} }) => {
    const { attachments } = this.state;
    const { status, originFileObj = {} } = file;

    const fileExists = attachments.reduce(
      (result, attachment) => result || attachment.id === originFileObj.uid,
      false
    );

    switch (status) {
      case DONE:
        this.onChangeDoneState(file, fileExists);
        break;
      case ERROR:
        this.onChangeErrorState(file, fileExists);
        break;
      case UPLOADING:
        this.onChangeUploadingState(file, fileExists);
        break;
      default:
        break;
    }

    return this.getPayload();
  };

  onTextFieldChange = (key, evt) => {
    const notes = evt.target.value;

    this.updateAttachmentOnStorage({ id: key, notes });
    this.updateSessionAttachmentNotes(key, notes);
  };

  renderAsOwner = (attachment, attachmentIndex) => {
    const { dataKey, validations: { rules = {} } = {} } = this.props;
    const { uploadingIds } = this.state;
    const uploadRules = rules[dataKey] || [];
    const notesRules = get(uploadRules, toPath('0.item.0.fields.notes'));
    const fieldDataKey = `${dataKey}[${attachmentIndex}].${attachment.id}-notes`;
    const fieldDataOriginalKey = `${dataKey}[${attachmentIndex}].notes`;
    const allowRemove = !uploadingIds.includes(attachment.id);

    return renderAttachmentAsOwner({
      props: this.props,
      setState: params => this.setState(params),
      attachment,
      fieldDataKey,
      fieldDataOriginalKey,
      notesRules,
      allowRemove,
      onRemoveFile: () => this.onRemoveFile(attachment, attachmentIndex),
      onTextFieldChange: (...params) => this.onTextFieldChange(...params),
    });
  };

  renderAsReviewer = (attachment = {}, attachmentIndex, timezone = null) => {
    const { dataKey } = this.props;

    const fieldDataKey = `${dataKey}[${attachmentIndex}].${attachment.id}-notes`;

    return renderAttachmentAsReviewer({
      props: this.props,
      setState: params => this.setState(params),
      fieldDataKey,
      attachment,
      timezone,
    });
  };

  isPrinting = () => this.props.isPrinting;

  renderAttachments = () => {
    const { attachments } = this.state;
    const { timezone } = this.props;
    return attachments.map((attachment, index) => {
      const key = attachment.id;

      return (
        <Row key={key} gutter={8}>
          {[ERROR, UPLOADING].includes(attachment.status) ||
          (isEditableStatus(this.props) &&
            !this.isPrinting() &&
            !this.isReviewer())
            ? this.renderAsOwner(attachment, index)
            : this.renderAsReviewer(attachment, index, timezone)}
        </Row>
      );
    });
  };

  isReviewer = () => {
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        return false;
      } else if (this.props.contributorAssignmentCanView) {
        return true;
      }
    }
    return !!this.props.isReviewer;
  };

  onStateChange = () => {
    const { dispatch } = this.props;
    const { attachments = {} } = this.state;
    dispatch(onStateChange(attachments));
  };

  saveUpload = node => {
    if (this.uploader) return null;
    this.uploader = node;
  };

  getUploadedFileUser = userId => {
    const {
      data: { __users: users },
    } = this.props;

    const { fullName, rank } = get(users, userId, {});
    return { fullName, rank };
  };

  fetchAttachments = props => {
    const { dataKey, meta: { id = '' } = {}, settings } = props;

    const storeData = get(props, ['data', dataKey], []);
    if (!id) {
      const isEmpty = !!Object.values(first(storeData) || {}).length;
      const initialValue = isEmpty ? [] : storeData;
      formActionDispatcher(
        settings.formName,
        syncFormData({ [dataKey]: initialValue })
      );
      return;
    }

    this.setState({ isMounted: true }, () => {
      const currentData = storeData.reduce(
        (prev, next) => ({
          ...prev,
          [next.id]: next.notes || next[`${next.id}-notes`] || '',
        }),
        {}
      );

      // if the form is closed, there is no 'reviewNotes' form, to see the attachment
      // we need to use 'form' as formName, keeping settings.formName for all the
      // other cases... you never know :)
      formActionDispatcher(
        settings.formName === 'reviewNotes' ? 'form' : settings.formName,
        fetchFormAttachments(
          {
            id: id,
            field: dataKey,
            filters: {
              include: {
                attachable: [{ submitter: ['rank'] }],
              },
              where: {
                cancelled: false,
              },
            },
            settings,
          },
          async (error, att) => {
            if (error) {
              return false;
            }
            const [, attachments] = att;
            this.setState(
              {
                attachments: await Promise.all(
                  attachments.map(async attachment => ({
                    ...attachment,
                    status: DONE,
                    notes: currentData[attachment.id] || '',
                    [`${attachment.id}-notes`]:
                      currentData[attachment.id] || '',
                    owner: attachment.owner,
                    fileUploadedBy: await this.getUploadedFileUser(
                      attachment.benchmarkUserId
                    ),
                  }))
                ),
              },
              () => {
                this.updateFormValues();
                this.removeBrokenReferences();
              }
            );
          }
        )
      );
    });
  };

  render() {
    const { attachments: { loading } = {} } = this.props;
    const { attachments = [] } = this.state;
    const totalAttachments = attachments.length;
    const disabled = getFieldIsDisabled(this.props);

    return (
      <div>
        {!this.isReviewer() && <AttachmentsWarning />}
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>{this.renderAttachments()}</Col>
            {loading && totalAttachments === 0 && (
              <Col span={24}>
                <h4>Retrieving attachments&hellip;</h4>
              </Col>
            )}
          </Row>
        </Spin>

        {!this.isReviewer() && (
          <Row gutter={50}>
            <Col span={24} className="text-right">
              <StyledLineDivide />
              <StyledUpload
                showUploadList={false}
                beforeUpload={onBeforeUpload(this.props)}
                name="fileData"
                headers={{
                  Authorization: storage.get('token'),
                }}
                action={getURL(this.props)}
                customRequest={uploadOverride(this.props)}
                onChange={this.onChange}
                onRemove={this.onRemoveFileOnStorage}
                ref={this.saveUpload}
                disabled={disabled}
              >
                <Button disabled={disabled}>
                  <Icon type="upload" /> Upload
                </Button>
                <FileSizeLimit />
              </StyledUpload>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    attachments,
    form: {
      selectedForm: {
        meta: { workFlowData = {} } = {},
        isAdminEdit = false,
      } = {},
      fileUploaded,
    } = {},
    app,
  } = state;
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);
  const data = dataSelector(state, props);
  const meta = (isEmpty(props.meta) ? data.meta : props.meta) || {};

  return {
    timezone: getAgencyTZ(state),
    attachments,
    workFlowData,
    blockNavigation: app.blockNavigation,
    data,
    meta,
    dataEnums: getDataEnums(state, props, formTemplate),
    fileUploaded,
    isAdminEdit,
  };
};

export default connect(mapStateToProps, null)(InputUpload);
