import React, { useState, useEffect } from 'react';
import { InputNumber, Form, Button } from 'antd';

import { Content, InputContainer, Title } from './CaseFileDeadline.styled';
import ModifyDaysToFollowUpButton from '../../../../caseFile/components/modifyDaysToFollowUpButton/ModifyDaysToFollowUpButton';
import caseFileEndpoints from '../../../../../api/caseFileEndpoints/caseFileEndpoints';
import {
  showError,
  showSuccess,
  MAXIMUM_DAYS_VALUE,
  MINIMUM_DAYS_VALUE,
} from '../../constants';

const CaseFileTypeDeadline = ({ casefileType, setCaseFileType = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [deadlineDays, setDeadlineDays] = useState();
  const [deadlineValidationStatus, setDeadlineValidationStatus] = useState(
    'success'
  );

  const { type, key, deadline } = casefileType;

  const checkIfNumberIsValid = newNumber => {
    let numberToSet = newNumber === 0 ? 0 : !newNumber ? null : newNumber;
    let newStatus = 'success';
    const errorConditions =
      typeof newNumber !== 'number' ||
      newNumber % 1 != 0 ||
      newNumber < MINIMUM_DAYS_VALUE ||
      newNumber > MAXIMUM_DAYS_VALUE;

    if (numberToSet !== null && errorConditions) {
      numberToSet = deadlineDays;
      newStatus = 'error';
    }
    setDeadlineDays(numberToSet);
    setDeadlineValidationStatus(newStatus);
  };

  const handleButtonNumberChange = operation => {
    let newNumber = deadlineDays || 1;
    if (operation === 'add') {
      newNumber = newNumber + 1;
    } else if (operation === 'subtract') {
      newNumber = newNumber - 1;
    }
    checkIfNumberIsValid(newNumber);
  };

  const sendCasefileDeadline = deadline => {
    const payload = { deadline };
    setLoading(true);
    caseFileEndpoints
      .saveCasefileTypeDeadline(key, payload)
      .then(_ => {
        showSuccess('Deadline saved successfully');
        setCaseFileType({ ...casefileType, deadline });
      })
      .catch(error => {
        showError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    sendCasefileDeadline(deadlineDays);
  };

  useEffect(() => {
    setDeadlineDays(deadline);
  }, []);

  return (
    <Content className="case-file-type-container">
      <Title>Deadline</Title>
      <Form onSubmit={handleSubmit}>
        <InputContainer>
          <span>{`${type} Case Files must be completed in`}</span>
          <ModifyDaysToFollowUpButton
            disabled={
              !deadlineDays || deadlineDays <= MINIMUM_DAYS_VALUE || loading
            }
            onClick={handleButtonNumberChange}
            operator="subtract"
          />

          <Form.Item
            colon={false}
            validateStatus={deadlineValidationStatus}
            style={{ marginBottom: '0' }}
          >
            <InputNumber
              disabled={loading}
              min={MINIMUM_DAYS_VALUE}
              max={MAXIMUM_DAYS_VALUE}
              value={deadlineDays}
              onChange={e => checkIfNumberIsValid(e)}
              onKeyDown={e => {
                if (e.key === '.' || e.key === '-') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>

          <ModifyDaysToFollowUpButton
            disabled={
              loading ||
              deadlineDays >= MAXIMUM_DAYS_VALUE ||
              deadlineValidationStatus === 'error'
            }
            onClick={handleButtonNumberChange}
            operator="add"
          />
          <span>days.</span>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 'auto' }}
            disabled={loading || deadlineValidationStatus === 'error'}
          >
            Save Deadline
          </Button>
        </InputContainer>
      </Form>
    </Content>
  );
};

export default CaseFileTypeDeadline;
