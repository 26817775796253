import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Row, Col, Form, Input, Select, DatePicker, Spin } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';

import {
  setUsersLoading,
  setUsersLoadingError,
  setUsersLoadingSuccessful,
} from './actions';
import { initialState, reducer } from './reducer';
import { BENCHMARK_DATE_FORMAT } from '../../../../../utils/parse-date';
import { EVENT_TYPE_LIST, TABLE_LIST } from './optionValues';
import organizationEndpoints from '../../../../../api/organization/organizationEndpoints';
import { showError } from '../../constants';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Item } = Form;

const CasefileAuditLogs = ({ handleFilterChange, agencyId, form, loading }) => {
  const [isLoadingFilteredData, setIsLoadingFilteredData] = useState(false);
  const [userState, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;

  const disabledYearAndHalfAgoAndFutureDays = current => {
    return !(
      current &&
      current >
        moment()
          .subtract(547, 'days')
          .endOf('day') &&
      current < moment().endOf('day')
    );
  };

  const fetchUsers = (searchValue = null, isOnFocus) => {
    if (userState.data.length > 0 && isOnFocus) {
      return;
    }

    dispatch(setUsersLoading());

    organizationEndpoints
      .getUsersSearch(agencyId, { fullName: searchValue }, false)
      .then(response => {
        const data = response.map(({ integrationId, fullName }) => ({
          id: integrationId,
          label: fullName,
        }));
        dispatch(setUsersLoadingSuccessful(data));
      })
      .catch(e => {
        showError(e);
        dispatch(setUsersLoadingError());
      });
  };

  const renderValuesOptions = values =>
    values.map(({ id, label }) => <Option key={id}>{label}</Option>);

  const filterOptionBySearch = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const sendFilters = newFilters => {
    setIsLoadingFilteredData(false);
    for (let [key, value] of Object.entries(newFilters)) {
      if (!value || (typeof value === 'object' && value.length === 0)) {
        delete newFilters[key];
        continue;
      }

      if (key === 'eventDate') {
        const startDate = value[0].startOf('day').format();
        const endDate = value[1].startOf('day').format();
        delete newFilters[key];
        newFilters['from'] = startDate;
        newFilters['to'] = endDate;
        continue;
      }
    }

    handleFilterChange(newFilters);
  };

  const handleCalendarChange = e => {
    const newFilters = form.getFieldsValue();
    newFilters['eventDate'] = e;
    sendFilters(newFilters);
  };

  const sendTextFilter = () => {
    const newFilters = form.getFieldsValue();
    sendFilters(newFilters);
  };

  const handleOnChangeText = e => {
    // clear icon is clicked
    if (e.type === 'click') {
      const newFilters = form.getFieldsValue();
      newFilters['casefileNumberPattern'] = e.target.value;
      sendFilters(newFilters);
    }
  };

  const handleSelectChange = useCallback(
    debounce((fieldName, e) => {
      const newFilters = form.getFieldsValue();
      newFilters[fieldName] = e;
      sendFilters(newFilters);
    }, 800),
    []
  );

  const debouncedfetchUsers = useCallback(debounce(fetchUsers, 800), []);

  useEffect(() => {
    setIsLoadingFilteredData(loading);
  }, [loading]);

  return (
    <div className="casefile-audit-log-container">
      <Form>
        <Row gutter={24}>
          <Col span={6}>
            <Item>
              {getFieldDecorator('eventDate')(
                <RangePicker
                  allowClear
                  disabledDate={disabledYearAndHalfAgoAndFutureDays}
                  onChange={handleCalendarChange}
                  format={BENCHMARK_DATE_FORMAT}
                  disabled={isLoadingFilteredData}
                />
              )}
            </Item>
          </Col>
          <Col span={5}>
            <Item>
              {getFieldDecorator('casefileNumberPattern')(
                <Input
                  allowClear
                  placeholder="Enter Casefile Id"
                  disabled={isLoadingFilteredData}
                  onPressEnter={sendTextFilter}
                  onChange={handleOnChangeText}
                />
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item>
              {getFieldDecorator('executedBy')(
                <Select
                  allowClear
                  showSearch
                  mode="default"
                  placeholder="Executed by"
                  filterOption={false}
                  disabled={isLoadingFilteredData}
                  onFocus={() => fetchUsers(null, true)}
                  onChange={e => handleSelectChange('executedBy', e)}
                  onSearch={value => debouncedfetchUsers(value, false)}
                  notFoundContent={
                    userState.loading ? <Spin size="small" /> : 'No users found'
                  }
                  dropdownStyle={{ position: 'fixed' }}
                  style={{ width: '100%' }}
                >
                  {renderValuesOptions(userState.data)}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item>
              {getFieldDecorator('eventTypes')(
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Event Type"
                  filterOption={filterOptionBySearch}
                  dropdownStyle={{ position: 'fixed' }}
                  onChange={e => handleSelectChange('eventTypes', e)}
                  disabled={isLoadingFilteredData}
                >
                  {EVENT_TYPE_LIST.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item>
              {getFieldDecorator('tables')(
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Section"
                  filterOption={filterOptionBySearch}
                  dropdownStyle={{ position: 'fixed' }}
                  onChange={e => handleSelectChange('tables', e)}
                  disabled={isLoadingFilteredData}
                >
                  {TABLE_LIST.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const WrappedCasefileAuditLogFilterForm = Form.create({
  name: 'casefile_audit_log_filters',
})(CasefileAuditLogs);

export default WrappedCasefileAuditLogFilterForm;
