import React, { useState, useEffect, useCallback } from 'react';
import { Button, Icon } from 'antd';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from 'APP_COMPONENTS/custom-sections/sections/Sections.styled';
import FilterableColumnTable from 'APP_COMPONENTS/filterable-column-table/FilterableColumnTable';
import getUserTrainingData from '../../../agency-training/actions/get-user-training-data.js';
import getUserTrainingDataCsv from '../../../agency-training/actions/get-user-training-data-csv';
import ModalTitle from '../../../../../components/common/modal/title';
import ModalBody from '../../../../../components/common/modal/body';
import createModal from '../../../../../actions/create-modal';
import showModal from '../../../../../actions/show-modal';
import generateTrainingConfigs from './GenerateTrainingConfigs.js';

const UserTrainingSection = ({
  tenantId,
  userId,
  dispatch,
  userProfile,
  session,
}) => {
  const configs = generateTrainingConfigs(session);
  if (!configs) return <></>;

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [trainingRows, setTrainingRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [sort, setSort] = useState('');
  const [filters, setFiltersState] = useState({});
  const setFilters = filters => {
    setFiltersState(
      Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => {
          return !!value;
        })
      )
    );
  };

  const pageSize = 10;

  const callback = useCallback((error, data) => {
    setLoading(false);
    if (error || !data || data['success'] !== true) {
      return;
    }
    const { trainings, totalElements } = data.content;
    setTotalElements(totalElements);
    setTrainingRows(trainings);
  });

  const fetchData = () => {
    const queryFilters = { ...filters, source: configs.source };
    setLoading(true);
    dispatch(
      getUserTrainingData({
        tenantId: tenantId,
        userId: userId,
        pageNumber: currentPage,
        pageSize: pageSize,
        sort: sort || 'statusOrder:asc,completionDate:desc',
        filters: queryFilters,
        next: callback,
      })
    );
  };

  useEffect(() => {
    if (userId && tenantId && isActive) {
      fetchData();
    }
  }, [userId, tenantId, sort, filters, currentPage, pageSize, isActive]);

  const fetchTableDataCallback = (pagination, filters, sort) => {
    if (sort?.column && sort?.order) {
      setSort(`${sort.column}:${sort.order === 'ascend' ? 'asc' : 'desc'}`);
    } else {
      setSort('');
    }
    setFilters(filters);
    setCurrentPage(pagination.currentPage);
  };

  const showErrorMessage = error => {
    const errorTitle = (
      <ModalTitle error>
        <Icon type="close-circle" /> <span>Heads Up!</span>
      </ModalTitle>
    );

    const successFields = (
      <ModalBody>
        <p>{error ?? 'Something wrong occurred, please try again later.'}</p>
      </ModalBody>
    );

    const options = {
      id: 'ou-error-modal',
      title: errorTitle,
      children: successFields,
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  const recordRenderCallback = (text, record, dataIndex) =>
    dataIndex === 'categoryNames' ? text.join('; ') :  text ;

  const handleDownloadButtonClick = event => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      getUserTrainingDataCsv({
        tenantId: tenantId,
        userId: userId,
        source: configs.source,
        next: (error, data) => {
          if (error) {
            showErrorMessage('Failed to fetch file.');
            return false;
          }

          const a = document.createElement('a');
          const blob = new Blob([data]);
          const url = window.URL.createObjectURL(blob);

          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = url;
          a.download = `${userProfile.firstName}_${
            userProfile.lastName
          }_Training_${new Date().toJSON().slice(0, 10)}.csv`;
          a.click();
          window.URL.revokeObjectURL(url);
        },
      })
    );
  };

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey={[]}
      expandIconPosition="right"
      onChange={() => {
        setIsActive(true);
      }}
    >
      <StyledPanel
        header={<StyledPanelTitle size="large">Training</StyledPanelTitle>}
        key="userTrainingRecord"
        className="section-panel"
        extra={
          <Button
            type="primary"
            ghost
            disabled={loading || !totalElements}
            onClick={handleDownloadButtonClick}
          >
            Export CSV
          </Button>
        }
      >
        <FilterableColumnTable
          columns={configs.columns}
          data={trainingRows}
          fetchDataCallback={fetchTableDataCallback}
          rowKey="id"
          pageSize={pageSize}
          loading={loading}
          totalRows={totalElements}
          recordRenderCallback={recordRenderCallback}
        />
      </StyledPanel>
    </StyledCollapse>
  );
};

export default UserTrainingSection;
