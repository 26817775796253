import React, { useEffect, useState, useMemo } from 'react';
import { Button, Menu, Checkbox, Dropdown, Icon, Row } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import preferences from '../../preferences/columns';

const { Item, Divider } = Menu;

const Title = styled.h3`
  font-weight: 600;
  padding: 6px 12px 0;
`;

const MenuRow = styled(Row)`
  padding: 6px 12px;
`;

const MenuButton = styled(Button)`
  font-size: 14px;
  border-radius: 4px;
  padding: 4px 10px;
`;

const ItemWrapper = styled(Item)`
  cursor: default;
  background: #fff;
  padding: 0;

  &:hover {
    background: #fff;
  }
`;

const COLUMNS_KEYS = preferences.getKeys();

const COLUMNS = Object.keys(COLUMNS_KEYS).reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: { checked: curr === 'casefileId' },
  }),
  {}
);

const KEY_DETAILS_COLUMNS = [
  'individuals',
  'outcomes',
  'reasons',
  'deadline',
  'status',
];

const ColumnsPreferences = ({
  loading,
  enableCasefileKeyDetails,
  selectedColumns,
  onApply,
  onReset,
}) => {
  const [columns, setColumns] = useState(COLUMNS);
  const [allChecked, setAllChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filteredColumns, filteredKeys] = useMemo(() => {
    if (enableCasefileKeyDetails) {
      return [columns, COLUMNS_KEYS];
    }
    let filteredColumns = columns;
    let filteredKeys = COLUMNS_KEYS;
    KEY_DETAILS_COLUMNS.forEach(key => {
      delete filteredColumns[key];
      delete filteredKeys[key];
    });
    return [filteredColumns, filteredKeys];
  }, [columns]);

  useEffect(() => {
    if (selectedColumns && !isEmpty(selectedColumns)) {
      if (!enableCasefileKeyDetails) {
        KEY_DETAILS_COLUMNS.forEach(key => {
          delete selectedColumns[key];
        });
      }
      setColumns(selectedColumns);
    }
  }, []);

  useEffect(() => {
    const isAllChecked = isAllEntriesChecked();
    if (isAllChecked) {
      setAllChecked(true);
    }
  }, [columns]);

  const handleToggleAll = () => {
    if (allChecked) {
      setColumns(prevState =>
        Object.keys(prevState).reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: { checked: curr === 'casefileId' ? true : false },
          }),
          prevState
        )
      );
      setAllChecked(false);
    } else {
      setColumns(prevState =>
        Object.keys(prevState).reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: { checked: true },
          }),
          prevState
        )
      );
      setAllChecked(true);
    }
  };

  const handleCheckboxClick = _key => {
    setColumns(prevState =>
      Object.entries(prevState).reduce((prev, curr) => {
        const [key, value] = curr;
        if (_key !== key) return { ...prev, [key]: value };
        return { ...prev, [key]: { checked: !value.checked } };
      }, prevState)
    );
  };

  const isAllEntriesChecked = () => {
    let counter = 0;
    const keys = Object.keys(COLUMNS_KEYS);
    keys.forEach(key => {
      if (!filteredColumns[key]?.checked) {
        counter++;
      }
    });
    return counter === 0;
  };

  const handleOnReset = () => {
    setColumns(prevState =>
      Object.keys(prevState).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: { checked: curr === 'casefileId' ? true : false },
        }),
        prevState
      )
    );
    setAllChecked(false);
    handleVisibleChange(false);
    onReset();
  };

  const handleOnApply = () => {
    handleVisibleChange(false);
    onApply(filteredColumns);
  };

  const handleVisibleChange = flag => setVisible(flag);

  const menu = (
    <Menu>
      <ItemWrapper key="dropdown-title">
        <Title>Table Preferences</Title>
      </ItemWrapper>
      <Divider />
      <Item key={'column-preferences-select-all'}>
        <Checkbox
          checked={allChecked}
          disabled={loading}
          onChange={() => handleToggleAll()}
        >
          Select All Columns / Deselect All Columns
        </Checkbox>
      </Item>
      {Object.entries(filteredKeys).map(([key, value]) => (
        <Item key={`column-preferences-${key}`}>
          <Checkbox
            checked={filteredColumns[key]?.checked}
            disabled={key === 'casefileId' || loading}
            onChange={() => handleCheckboxClick(key)}
          >
            {value}
          </Checkbox>
        </Item>
      ))}
      <Divider />
      <ItemWrapper key="dropdown-actions">
        <MenuRow type="flex" align="middle" justify="space-between">
          <MenuButton
            type="danger"
            size="small"
            onClick={() => handleOnReset()}
            disabled={loading}
          >
            Reset
          </MenuButton>
          <MenuButton
            type="primary"
            size="small"
            onClick={() => handleOnApply()}
            disabled={loading}
          >
            Apply
          </MenuButton>
        </MenuRow>
      </ItemWrapper>
    </Menu>
  );

  const DropdownButton = ({ onClick }) => (
    <a className="ant-dropdown-link" onClick={onClick}>
      <Icon type="setting" theme="outlined" style={{ fontSize: 32 }} />
    </a>
  );

  return (
    <Dropdown
      overlay={menu}
      visible={visible}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
    >
      <DropdownButton onClick={e => e.preventDefault()} />
    </Dropdown>
  );
};

export default ColumnsPreferences;
