import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { debounce, isEmpty } from 'lodash';
import {
  Select,
  Button,
  DatePicker,
  Form,
  Spin,
  notification,
  Icon,
} from 'antd';
import moment from 'moment';
import { unescape as htmlUnescape } from 'html-escaper';
import caseFileEndpoints from '../../../../api/caseFileEndpoints/caseFileEndpoints';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';
import {
  setStatusesLoading,
  setStatusesLoadingSuccessful,
  setStatusesLoadingError,
  setOutcomesLoading,
  setOutcomesLoadingSuccessful,
  setOutcomesLoadingError,
  setReasonsLoading,
  setReasonsLoadingSuccessful,
  setReasonsLoadingError,
} from './actions';
import { initialState, reducer } from './reducer';
import {
  AdvanceFilterWrapper,
  FormButtons,
  StyledButton,
} from './CaseFileAdvancedFilters.styled';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Item } = Form;

const dateFields = ['deadline', 'createdDate'];
const OWNERS = 'owners';
const INDIVIDUALS = 'individuals';

const CaseFileAdvancedFilters = ({
  setTableData,
  tenantId,
  agencyId,
  enableCasefileKeyDetails,
  form,
  ...props
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [owners, setOwners] = useState([]);
  const [individuals, setIndividuals] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState({
    owners: false,
    individuals: false,
  });
  const [casefileTypes, setCasefileTypes] = useState({
    data: [],
    isLoading: true,
  });
  const [selectedUsers, setSelectedUsers] = useState({
    owners: { ids: [], labels: [] },
    individuals: { ids: [], labels: [] },
  });
  const [keyDetails, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;

  const USERS_MAP = {
    owners: {
      users: owners,
      updateStateFn: state => setOwners(state),
    },
    individuals: {
      users: individuals,
      updateStateFn: state => setIndividuals(state),
    },
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const NOTIFICATION_ERROR = 'error';

  function showError(err) {
    const message = err.message || 'API response is not ok';
    showNotification(NOTIFICATION_ERROR, 'Error', message);
  }

  function showNotification(type, message, description) {
    return notification[type]({ message, description });
  }

  const disabledFutureDays = current => {
    return !(current && current < moment().endOf('day'));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newFilters = form.getFieldsValue();
    normalizeFilters(newFilters);
    setFilters(form.getFieldsValue());
    setTableData(newFilters);
    setIsFilterVisible(false);
  };

  const normalizeFilters = newFilters => {
    for (let [key, value] of Object.entries(newFilters)) {
      if (
        !value ||
        (dateFields.includes(key) && value.length === 0) ||
        (typeof value === 'object' && value.length === 0)
      ) {
        delete newFilters[key];
        continue;
      }

      if (key === 'deadline') {
        const { startDate, endDate } = getStartAndEndDates(value);
        delete newFilters[key];
        newFilters['deadlineStart'] = startDate;
        newFilters['deadlineEnd'] = endDate;
        continue;
      }

      if (key === 'createdDate') {
        const { startDate, endDate } = getStartAndEndDates(value);
        delete newFilters[key];
        newFilters['createdAtStart'] = startDate;
        newFilters['createdAtEnd'] = endDate;
        continue;
      }
    }
  };

  const handleResetFilters = () => {
    form.resetFields();
    setFilters({});
    setTableData({});
    setIsFilterVisible(false);
    setOwners([]);
    setIndividuals([]);
    setSelectedUsers({
      owners: { ids: [], labels: [] },
      individuals: { ids: [], labels: [] },
    });
  };

  const fetchStatuses = () => {
    if (keyDetails.statuses.length > 0) {
      return;
    }

    dispatch(setStatusesLoading());
    caseFileEndpoints
      .getStatusListDashboard(tenantId)
      .then(response => {
        const statuses = formatDataValuesStatuses(response);
        dispatch(setStatusesLoadingSuccessful(statuses));
      })
      .catch(e => {
        showError(e);
        dispatch(setStatusesLoadingError());
      });
  };

  const fetchReasons = () => {
    if (keyDetails.reasons.length > 0) {
      return;
    }

    dispatch(setReasonsLoading());
    caseFileEndpoints
      .getReasonsListDashboard(tenantId)
      .then(response => {
        const reasons = formatDataValuesLikeLabels(response);
        dispatch(setReasonsLoadingSuccessful(reasons));
      })
      .catch(e => {
        showError(e);
        dispatch(setReasonsLoadingError());
      });
  };

  const fetchOutcomes = () => {
    if (keyDetails.outcomes.length > 0) {
      return;
    }

    dispatch(setOutcomesLoading());
    caseFileEndpoints
      .getOutcomesListDashboard(tenantId)
      .then(response => {
        const outcomes = formatDataValuesLikeLabels(response);
        dispatch(setOutcomesLoadingSuccessful(outcomes));
      })
      .catch(e => {
        showError(e);
        dispatch(setOutcomesLoadingError());
      });
  };

  const fetchUsers = (
    searchValue = null,
    isOnFocus,
    users,
    updateStateFn,
    fieldName,
    newUsers = { owners: [], individuals: [] }
  ) => {
    if (
      users.length > 0 &&
      isOnFocus &&
      isEmpty(selectedUsers[fieldName].ids)
    ) {
      return;
    }
    setLoadingUsers(prevState => ({ ...prevState, [fieldName]: true }));
    organizationEndpoints
      .getUsersSearch(agencyId, { fullName: searchValue }, false)
      .then(response => {
        const data = response.map(({ integrationId, fullName }) => ({
          id: integrationId,
          label: fullName,
        }));
        if (newUsers[fieldName] && !isEmpty(newUsers[fieldName])) {
          const reducedUsers = reduceUsers(newUsers[fieldName]);
          reducedUsers.forEach(user => {
            const isUserInArray = data.findIndex(u => u.id === user.id);
            if (isUserInArray === -1) {
              data.unshift({ id: user.id, label: user.label });
            }
          });
        }
        updateStateFn(data);
      })
      .catch(e => showError(e))
      .finally(() =>
        setLoadingUsers(prevState => ({ ...prevState, [fieldName]: false }))
      );
  };

  const debouncedFetchUsers = useCallback(debounce(fetchUsers, 800), []);

  const fetchCasefileTypes = () => {
    if (casefileTypes.data.length > 0) {
      return;
    }
    setCasefileTypes({ ...casefileTypes, isLoading: true });
    caseFileEndpoints
      .getCasefileTypes(tenantId)
      .then(response => {
        const data = response.content.map(({ id, name }) => ({
          id,
          label: htmlUnescape(name),
        }));
        setCasefileTypes({ ...casefileTypes, data });
      })
      .catch(e => {
        showError(e);
      })
      .finally(setCasefileTypes({ ...casefileTypes, isLoading: false }));
  };

  const formatDataValuesStatuses = ({ content }) => {
    const newArray = content.map(({ id, label, casefileTypeId }) => ({
      id,
      casefileTypeId,
      label: htmlUnescape(label),
    }));
    return newArray;
  };

  const formatDataValuesLikeLabels = ({ content }) => {
    const uniqueValues = [];
    content.forEach(element => {
      const isAlreadyInArray = uniqueValues.includes(element.label);
      if (!isAlreadyInArray) {
        uniqueValues.push(htmlUnescape(element.label));
      }
    });

    const newArray = uniqueValues.map(el => ({ id: el, label: el }));
    return newArray;
  };

  const renderUserValuesOptions = (values, fieldName) =>
    values.map(({ id, label }) => (
      <Option key={id} disabled={loadingUsers[fieldName]}>
        {label}
      </Option>
    ));

  const renderValuesOptions = values =>
    values.map(({ id, label }) => <Option key={id}>{label}</Option>);

  const renderValuesLabelOptions = values =>
    values.map(({ id, label }) => (
      <Option key={id} value={label}>
        {label}
      </Option>
    ));

  const filtersIcon = isFilterVisible ? 'minus' : 'plus';

  const filterOptionBySearch = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleSelectChange = (fieldName, e, option) => {
    const newFilters = form.getFieldsValue();
    newFilters[fieldName] = e;
    updateFilters(newFilters);
    if (fieldName === OWNERS || fieldName === INDIVIDUALS) {
      const { [fieldName]: usersIds } = newFilters;
      const newUsers = mapFilterToSelectedUser(
        usersIds,
        USERS_MAP[fieldName].users
      );
      const _selectedUsers = { owners: [], individuals: [] };
      _selectedUsers[fieldName] = newUsers;
      const isNewUser = _selectedUsers[fieldName].ids.findIndex(id =>
        e.includes(id)
      );
      if (isNewUser === -1) {
        _selectedUsers[fieldName].ids = e;
        const reducedLabels = option.reduce(
          (prev, curr) => [...prev, curr.props.children],
          []
        );
        _selectedUsers[fieldName].labels.push(...reducedLabels);
      }
      fetchUsers(
        null,
        false,
        USERS_MAP[fieldName].users,
        USERS_MAP[fieldName].updateStateFn,
        fieldName,
        newUsers
      );
    }
  };

  const updateFilters = newFilters => {
    normalizeFilters(newFilters);
    setFilters(form.getFieldsValue());
  };

  const handleFieldOnBlur = fieldName => {
    switch (fieldName) {
      case OWNERS:
        const newOwners = reduceUsers(selectedUsers.owners);
        setOwners(newOwners);
        break;

      case INDIVIDUALS:
        const newIndividuals = reduceUsers(selectedUsers.individuals);
        setIndividuals(newIndividuals);
        break;
    }
  };

  const getStartAndEndDates = date => {
    const startDate = date[0].format('YYYY-MM-DD');
    const endDate = date[1].format('YYYY-MM-DD');
    return { startDate, endDate };
  };

  const reduceUsers = users => {
    if (!users || isEmpty(users)) return [];
    return users?.ids?.map((id, index) => ({
      id: id,
      label: users.labels[index],
    }));
  };

  const mapFilterToSelectedUser = (ids, users) => {
    if (!ids || isEmpty(ids)) {
      return { ids: [], labels: [] };
    }
    const filtered = users.filter(u => ids.includes(u.id));
    return filtered.reduce(
      (prev, curr) => {
        return {
          ids: [...prev.ids, curr.id],
          labels: [...prev.labels, curr.label],
        };
      },
      { ids: [], labels: [] }
    );
  };

  useEffect(() => {
    const newFilters = form.getFieldsValue();
    if (!newFilters || Object.keys(newFilters).length === 0) {
      return;
    }
    const { owners: ownersIds, individuals: individualsIds } = newFilters;
    const newOwners = mapFilterToSelectedUser(ownersIds, owners);
    const newIndividuals = mapFilterToSelectedUser(individualsIds, individuals);
    setSelectedUsers(prevState => {
      const ownersNotMapped = newOwners.ids.reduce(
        (prev, curr, index) => {
          const isNotMapped =
            prevState.owners.ids.findIndex(id => id === curr) === -1;
          if (isNotMapped) {
            return {
              ids: [...prev.ids, curr],
              labels: [...prev.labels, newOwners.labels[index]],
            };
          }
          return prev;
        },
        { ids: [], labels: [] }
      );

      const individualsNotMapped = newIndividuals.ids.reduce(
        (prev, curr, index) => {
          const isNotMapped =
            prevState.individuals.ids.findIndex(id => id === curr) === -1;
          if (isNotMapped) {
            return {
              ids: [...prev.ids, curr],
              labels: [...prev.labels, newIndividuals.labels[index]],
            };
          }
          return prev;
        },
        { ids: [], labels: [] }
      );

      return {
        owners: {
          ids: [...prevState.owners.ids, ...ownersNotMapped.ids],
          labels: [...prevState.owners.labels, ...ownersNotMapped.labels],
        },
        individuals: {
          ids: [...prevState.individuals.ids, ...individualsNotMapped.ids],
          labels: [
            ...prevState.individuals.labels,
            ...individualsNotMapped.labels,
          ],
        },
      };
    });
    setFilters(newFilters);
  }, [form]);

  useEffect(() => {
    if (!isFilterVisible || Object.keys(filters).length === 0) {
      return;
    }
    const { owners: _owners, individuals: _individuals } = selectedUsers;
    const selectedOwners = reduceUsers(_owners);
    const selectedIndividuals = reduceUsers(_individuals);
    setOwners(selectedOwners);
    setIndividuals(selectedIndividuals);
    form.setFieldsValue(filters);
  }, [isFilterVisible]);

  const getFilteredStatuses = useCallback(() => {
    const filters = form.getFieldsValue();
    const { casefileTypeIds } = filters;
    if (
      casefileTypeIds &&
      !isEmpty(casefileTypeIds) &&
      statuses &&
      !isEmpty(statuses.value)
    ) {
      let filteredStatuses = [];
      casefileTypeIds.forEach(id => {
        const statusesToAdd = statuses?.value.filter(
          status => status.casefileTypeId === id
        );
        filteredStatuses = filteredStatuses.concat(statusesToAdd);
      });
      const uniqueFiltered = filterUniqueStatuses(filteredStatuses);
      return { loading: statuses.loading, value: uniqueFiltered };
    }
    const uniqueStatuses = filterUniqueStatuses(statuses.value);
    return { loading: statuses.loading, value: uniqueStatuses };
  }, [filters, keyDetails]);

  const filterUniqueStatuses = arr => {
    const uniqueValues = [];
    arr.forEach(element => {
      const isAlreadyInArray =
        uniqueValues.findIndex(el => el.label === element.label) > -1;
      if (!isAlreadyInArray) {
        uniqueValues.push({ ...element, label: htmlUnescape(element.label) });
      }
    });
    return uniqueValues;
  };

  const { statuses, reasons, outcomes } = keyDetails;
  const filteredStatuses = getFilteredStatuses();
  return (
    <div>
      <StyledButton
        type={isFilterVisible ? 'secondary' : 'primary'}
        onClick={() => setIsFilterVisible(!isFilterVisible)}
      >
        <Icon type={filtersIcon} /> Advanced Filters
      </StyledButton>
      {isFilterVisible && (
        <AdvanceFilterWrapper>
          <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Item label="Owner">
              {getFieldDecorator(OWNERS)(
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Owner"
                  onFocus={() =>
                    fetchUsers(null, true, owners, setOwners, OWNERS)
                  }
                  onBlur={() => handleFieldOnBlur(OWNERS)}
                  onChange={(e, option) =>
                    handleSelectChange(OWNERS, e, option)
                  }
                  onSearch={value =>
                    debouncedFetchUsers(value, false, owners, setOwners, OWNERS)
                  }
                  notFoundContent={
                    loadingUsers[OWNERS] ? (
                      <Spin size="small" />
                    ) : (
                      'No users found'
                    )
                  }
                  filterOption={filterOptionBySearch}
                  dropdownStyle={{ position: 'fixed' }}
                  loading={loadingUsers[OWNERS]}
                  style={{ maxWidth: '450px' }}
                >
                  {renderUserValuesOptions(owners, OWNERS)}
                </Select>
              )}
            </Item>
            <Item label="Creation Date">
              {getFieldDecorator('createdDate', {
                rules: [{ required: false }],
              })(
                <RangePicker
                  allowClear
                  disabledDate={disabledFutureDays}
                  onChange={e => handleSelectChange('createdDate', e)}
                  style={{ width: '100%', maxWidth: '450px' }}
                />
              )}
            </Item>
            <Item label="Case File Types">
              {getFieldDecorator('casefileTypes')(
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Case File Types"
                  onFocus={fetchCasefileTypes}
                  onChange={e => handleSelectChange('casefileTypes', e)}
                  notFoundContent={
                    casefileTypes.isLoading ? (
                      <Spin size="small" />
                    ) : (
                      'No case file types found'
                    )
                  }
                  filterOption={filterOptionBySearch}
                  dropdownStyle={{ position: 'fixed' }}
                  style={{ maxWidth: '450px' }}
                >
                  {renderValuesOptions(casefileTypes.data)}
                </Select>
              )}
            </Item>
            {enableCasefileKeyDetails && (
              <Item label="Deadline">
                {getFieldDecorator('deadline')(
                  <RangePicker
                    allowClear
                    onChange={e => handleSelectChange('deadline', e)}
                    style={{ width: '100%', maxWidth: '450px' }}
                  />
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Status">
                {getFieldDecorator('statuses')(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    loading={statuses.loading}
                    placeholder="Status"
                    onFocus={fetchStatuses}
                    onChange={e => handleSelectChange('statuses', e)}
                    notFoundContent={
                      statuses.loading ? (
                        <Spin size="small" />
                      ) : (
                        'No statuses found'
                      )
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                    style={{ maxWidth: '450px' }}
                  >
                    {renderValuesLabelOptions(filteredStatuses.value)}
                  </Select>
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Reasons">
                {getFieldDecorator('reasons')(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    loading={reasons.loading}
                    placeholder="Reasons"
                    onFocus={fetchReasons}
                    onChange={e => handleSelectChange('reasons', e)}
                    notFoundContent={
                      reasons.loading ? (
                        <Spin size="small" />
                      ) : (
                        'No reasons found'
                      )
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                    style={{ maxWidth: '450px' }}
                  >
                    {renderValuesLabelOptions(reasons.value)}
                  </Select>
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Outcomes">
                {getFieldDecorator('outcomes')(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    loading={outcomes.loading}
                    placeholder="Outcomes"
                    onFocus={fetchOutcomes}
                    onBlur={() => handleFieldOnBlur(INDIVIDUALS)}
                    onChange={e => handleSelectChange('outcomes', e)}
                    notFoundContent={
                      outcomes.loading ? (
                        <Spin size="small" />
                      ) : (
                        'No reasons found'
                      )
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                    style={{ maxWidth: '450px' }}
                  >
                    {renderValuesLabelOptions(outcomes.value)}
                  </Select>
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Individuals">
                {getFieldDecorator(INDIVIDUALS)(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    placeholder="Individuals"
                    onFocus={() =>
                      fetchUsers(
                        null,
                        true,
                        individuals,
                        setIndividuals,
                        INDIVIDUALS
                      )
                    }
                    onBlur={() => handleFieldOnBlur(INDIVIDUALS)}
                    onChange={(e, option) =>
                      handleSelectChange(INDIVIDUALS, e, option)
                    }
                    onSearch={value =>
                      debouncedFetchUsers(
                        value,
                        false,
                        individuals,
                        setIndividuals,
                        INDIVIDUALS
                      )
                    }
                    notFoundContent={
                      loadingUsers[INDIVIDUALS] ? (
                        <Spin size="small" />
                      ) : (
                        'No users found'
                      )
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                    loading={loadingUsers[INDIVIDUALS]}
                    style={{ maxWidth: '450px' }}
                  >
                    {renderUserValuesOptions(individuals, INDIVIDUALS)}
                  </Select>
                )}
              </Item>
            )}
            <FormButtons>
              <Button type="danger" onClick={handleResetFilters}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit">
                Apply
              </Button>
            </FormButtons>
          </Form>
        </AdvanceFilterWrapper>
      )}
    </div>
  );
};

const WrappedCaseFileAdvancedFilters = Form.create({
  name: 'casefile_filters',
})(CaseFileAdvancedFilters);

export default WrappedCaseFileAdvancedFilters;
