import { isEmpty } from 'lodash';
import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

const CASE_FILES_COLUMNS_KEYS = [
  'casefileId',
  'name',
  'description',
  'createdByFullName',
  'createdAt',
  'individuals',
  'reasons',
  'outcomes',
  'deadline',
  'status',
];

class configurationEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3004';
    super(
      process.env.REACT_APP_CONFIGURATION_SERVICE_URI || DEV_URI,
      'configuration/v1'
    );
  }

  getCalculatedConfig(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/userConfigs/calculatedConfig/agencies/:agencyId'
        ),
        method: 'GET',
      },
      { agencyId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getAgencyProfileConfiguration(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs/agencies/:agencyId'),
        method: 'GET',
      },
      { agencyId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  createAgencyProfileConfiguration(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs/agencies/:agencyId'),
        method: 'POST',
      },
      { agencyId },
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  updateAgencyProfileConfiguration() {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs'),
        method: 'PUT',
      },
      {},
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  deleteAgencyProfileConfiguration(configId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs/{userConfigId}'),
        method: 'DELETE',
      },
      { configId },
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getTenantFeaturesSlim(tenantId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/features/slim'),
        method: 'GET',
      },
      { tenantId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getFeatureStatus(featureName) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/features/feature/:featureName'),
        method: 'GET',
      },
      { featureName },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getCaseFileListColumnPreferences = async () => {
    const fetchParams = this.buildFetchParams({
      path: this.withApiPrefix('/configurations/casefile:dashboard'),
      method: 'GET',
    });
    try {
      const [response,] = await fetchRequest(fetchParams.URI, fetchParams.request);
      if (response && response.value) {
        const { value } = response;
        const columns = JSON.parse(value);
        if (isEmpty(columns)) {
          return null;
        }
        const formattedColumns = CASE_FILES_COLUMNS_KEYS.reduce(
          (prev, curr) => {
            const checked = columns.findIndex(column => column === curr) > -1;
            return { ...prev, [curr]: { checked } };
          },
          {}
        );
        // Guarantee we are always showing the casefileId column
        formattedColumns.casefileId = { checked: true };
        return formattedColumns;
      }
      return null;
    } catch (_) {
      return null;
    }
  };

  updateCaseFileListColumnPreferences = columns => {
    const payload = {
      value: JSON.stringify(columns),
    };
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/configurations/casefile:dashboard/preference/columns'
        ),
        method: 'PUT',
      },
      {},
      {},
      payload
    );

    return fetch(fetchParams.URI, fetchParams.request).then(response => {
      if ([200, 201].indexOf(response.status) === -1) {
        return false;
      }
      return true;
    });
  };
}

export default new configurationEndpoints();
