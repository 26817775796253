import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Spin,
  Tooltip,
  Icon,
  Divider,
} from 'antd';

import caseFileEndpoints from '../../../../../api/caseFileEndpoints/caseFileEndpoints';
import { InlineInputWrapper } from '../../../components/input';
import fetchReadableStream from '../../../../caseFile/utils/fetchReadableStream';
import parseDate, {
  BENCHMARK_DATE_FORMAT,
} from '../../../../../utils/parse-date';
import {
  showError,
  showSuccess,
  showNotification,
  NOTIFICATION_ERROR,
} from '../../constants';

const Item = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const CaseFileTypeForm = ({
  form,
  tenantId,
  timezone,
  casefileType,
  isCreatingCaseFile = true,
  closeModal = () => {},
  afterCreationAction = () => {},
  afterDeleteAction = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const { getFieldDecorator, validateFields } = form;
  const isLoadingData = !isCreatingCaseFile && loadingCategories;

  useEffect(() => {
    fetchCaseFileCategories();

    if (!isCreatingCaseFile) {
      form.setFieldsValue({
        name: casefileType.type,
        categoryId: casefileType.categoryId,
        abbreviation: casefileType.abbreviation,
      });
    }
  }, [loadingCategories]);

  useEffect(() => {
    return () => {
      if (isCreatingCaseFile) {
        form.resetFields();
      }
    };
  }, [closeModal]);

  const sanitizedFields = () => {
    const fields = {};
    Object.entries(form.getFieldsValue()).map(([key, value]) => {
      fields[key] = value.replace(/\s+/g, " ").trim();
    });
    return fields;
  };

  const resetAndCloseForm = () => {
    if (isCreatingCaseFile) {
      form.resetFields();
    }
    closeModal();
  };

  const showProcessedError = response => {
    const message = response.errors[0].message || 'API response is not ok';
    showNotification(NOTIFICATION_ERROR, 'Error', message);
  };

  const handleSubmit = e => {
    e.preventDefault();

    validateFields(err => {
      if (err) {
        return;
      }

      setLoading(true);
      caseFileEndpoints
        .saveCaseFleType({ ...sanitizedFields(), tenantId })
        .then(() => {
          showSuccess('Case File Type created successfully.');
          afterCreationAction();
          resetAndCloseForm();
        })
        .catch(err => {
          fetchReadableStream(err.response.body, showProcessedError);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const fetchCaseFileCategories = () => {
    if (categories.length > 0) {
      return;
    }

    setLoadingCategories(true);
    caseFileEndpoints
      .getCaseFileTypesCategories()
      .then(response => {
        const newCategories = response.content.data.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        setCategories(newCategories);
      })
      .catch(() => {
        showError('Could not load categories');
      })
      .finally(() => {
        setLoadingCategories(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    caseFileEndpoints
      .deleteCasefileType(casefileType.key)
      .then(() => {
        showSuccess('Case File Type deleted successfully');
        afterDeleteAction();
      })
      .catch(err => {
        fetchReadableStream(err.response.body, showProcessedError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderOptions = () => {
    return categories.map(({ label, value }) => (
      <Option key={value}>{label}</Option>
    ));
  };

  const renderDeleteButton = () => {
    const isDeleteDisabled = !casefileType?.allowsDeletion;
    const DeleteButton = (
      <Button
        loading={loading}
        type={isDeleteDisabled ? 'default' : 'danger'}
        icon="delete"
        onClick={handleDelete}
        disabled={isDeleteDisabled}
      >
        Delete
      </Button>
    );

    if (isDeleteDisabled) {
      return (
        <Tooltip title="This case file type cannot be deleted because it is currently in use. To delete this case file type, please delete the case files using this case file type.">
          {DeleteButton}
        </Tooltip>
      );
    }

    return DeleteButton;
  };

  const renderDate = date =>
    parseDate(date, timezone, BENCHMARK_DATE_FORMAT, false);

  return (
    <>
      {isLoadingData ? (
        <Row type="flex" justify="center" style={{ padding: '50px 40px' }}>
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : (
        <Form
          {...formItemLayout}
          layout="vertical"
          onSubmit={handleSubmit}
          style={{ marginTop: '16px' }}
        >
          <Item label="Category:">
            {getFieldDecorator('categoryId', {
              rules: [{ required: true, message: 'Category is required' }],
            })(
              <Select
                style={{ border: '1px !important' }}
                notFoundContent={
                  loadingCategories ? (
                    <Spin size="small" />
                  ) : (
                    'No categories found'
                  )
                }
                disabled={!isCreatingCaseFile}
              >
                {renderOptions()}
              </Select>
            )}
          </Item>
          <Item label="Name:">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  max: 100,
                  message: 'Name length must be up to 100 characters',
                },
              ],
            })(<Input disabled={!isCreatingCaseFile} />)}
          </Item>
          <Item
            label={
              <>
                <span>Abbreviation:</span>
                <Tooltip title="This abbreviation will be the prefix for the Case File ID">
                  <Icon type="info-circle" />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('abbreviation', {
              rules: [
                {
                  required: true,
                  pattern: /^[A-Z]{1,10}$/,
                  message:
                    'Abbreviation must use capital letters only. Length limit: 10',
                },
              ],
            })(<Input disabled={!isCreatingCaseFile} />)}
          </Item>

          {!isCreatingCaseFile && (
            <InlineInputWrapper
              label="Created on"
              options={{ labelCol: 'ant-col-8', inputCol: 'ant-col-16' }}
            >
              <span className="ant-form-text ant-form-text-field">
                {renderDate(casefileType?.createdAt)}
              </span>
            </InlineInputWrapper>
          )}
          <Divider style={{ marginTop: '24px', marginBottom: '16px' }} />
          <Row type="flex" justify="space-between">
            <Col style={{ display: isCreatingCaseFile ? 'none' : 'block' }}>
              {renderDeleteButton()}
            </Col>
            <Col>
              <Button type="default" icon="close" onClick={resetAndCloseForm}>
                Cancel
              </Button>
            </Col>
            <Col style={{ display: isCreatingCaseFile ? 'block' : 'none' }}>
              <Button
                type="primary"
                htmlType="submit"
                icon="save"
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default Form.create({ name: 'case_file_type_creation' })(
  CaseFileTypeForm
);
