import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, throttle, isEmpty } from 'lodash';
import StyledLastSaveDate from './ContributorLastSaveDate.styled';
import parseDate, {
  BENCHMARK_DATE_TIMEZ_FORMAT_WITH_PREPOSITION,
} from 'APP_ROOT/utils/parse-date';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import {
  getSectionDetails,
  findActiveSection,
} from '../utils/contributor-assigned-section';

export class ContributorLastSaveDate extends Component {
  state = {
    lastSaveDate: undefined,
  };

  componentDidMount() {
    const initialSectionDetails = getSectionDetails(this.props);
    const initialLastSaveDate = this.parseDate(
      initialSectionDetails.lastSaveDate
    );
    this.setState({ lastSaveDate: initialLastSaveDate });
  }

  componentDidUpdate(prevProps) {
    if (!this.activeSectionFound()) return;

    if (this.sectionCompleteChanged(prevProps)) return;

    this.updateIfLastSaveDateChanged(prevProps);
  }

  activeSectionFound() {
    const { activeSection } = this.props;
    if (isEmpty(activeSection)) return false;

    const currentSectionDetails = getSectionDetails(this.props);
    const activeSectionId = findActiveSection(this.props);
    const currentSectionId = currentSectionDetails.sectionId;

    return activeSectionId === currentSectionId;
  }

  sectionCompleteChanged(prevProps) {
    const currentSectionDetails = getSectionDetails(this.props);
    const prevSectionDetails = getSectionDetails(prevProps);
    return currentSectionDetails.complete !== prevSectionDetails.complete;
  }

  updateIfLastSaveDateChanged(prevProps) {
    const { lastSaveDate } = this.props;
    const prevLastSaveDate = prevProps.lastSaveDate;

    if (lastSaveDate !== prevLastSaveDate) {
      this.updateLastSaveDate(lastSaveDate);
    }
  }

  updateLastSaveDate = throttle(
    lastsaveDate => {
      const formattedDate = this.parseDate(lastsaveDate);
      this.setState({ lastSaveDate: formattedDate });
    },
    5000,
    { leading: true, trailing: false }
  );

  parseDate = lastSaveDate => {
    if (isEmpty(lastSaveDate)) {
      return;
    }

    const { timezone } = this.props;
    return parseDate(
      lastSaveDate,
      timezone,
      BENCHMARK_DATE_TIMEZ_FORMAT_WITH_PREPOSITION
    );
  };

  render() {
    const { lastSaveDate } = this.state;
    if (isEmpty(lastSaveDate)) {
      return null;
    }

    return <StyledLastSaveDate>Last saved {lastSaveDate}</StyledLastSaveDate>;
  }
}

const mapState = state => {
  const selectedForm = get(state, 'form.selectedForm', {});
  const timezone = getAgencyTZ(state);
  const lastSaveDate = get(selectedForm, 'lastUpdate.lastSaveDate', undefined);
  const activeSection = get(selectedForm, 'lastUpdate.activeSection', {});

  return {
    timezone,
    selectedForm,
    lastSaveDate,
    activeSection,
  };
};

export default connect(mapState)(ContributorLastSaveDate);
