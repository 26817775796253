import React, { useState } from 'react';
import { InputNumber } from 'antd';
import ModifyDaysToFollowUpButton from '../../../../caseFile/components/modifyDaysToFollowUpButton/ModifyDaysToFollowUpButton';

const MINIMUM_DAYS_VALUE = 0;
const MAXIMUM_DAYS_VALUE = 999999;

const DeadlinePredefinedTask = ({
  deadlineDays,
  deadlineValidationStatus,
  caseFileTypeDeadline = MAXIMUM_DAYS_VALUE,
  warningState = { showWarning: false, setShowWarning: () => {} },
  setDeadlineDays = () => {},
  setDeadlineValidationStatus = () => {},
}) => {
  const checkIfNumberIsValid = newNumber => {
    const { setShowWarning } = warningState;

    setShowWarning(false);
    let numberToSet = newNumber === 0 ? 0 : !newNumber ? null : newNumber;
    let newStatus = 'success';
    const errorConditions =
      typeof newNumber !== 'number' ||
      newNumber % 1 != 0 ||
      newNumber < MINIMUM_DAYS_VALUE ||
      newNumber > MAXIMUM_DAYS_VALUE;

    if (numberToSet !== null && errorConditions) {
      numberToSet = deadlineDays;
      newStatus = 'error';
    }

    if (
      !errorConditions &&
      caseFileTypeDeadline &&
      numberToSet > caseFileTypeDeadline
    ) {
      newStatus = 'success';
      setShowWarning(true);
    }

    setDeadlineDays(numberToSet);
    setDeadlineValidationStatus(newStatus);
  };

  const handleButtonNumberChange = operation => {
    let newNumber = deadlineDays || 0;
    if (operation === 'add') {
      newNumber = newNumber + 1;
    } else if (operation === 'subtract') {
      newNumber = newNumber - 1;
    }
    checkIfNumberIsValid(newNumber);
  };

  return (
    <>
      <div>
        <span>This task must be completed in </span>
        <ModifyDaysToFollowUpButton
          operator="subtract"
          onClick={handleButtonNumberChange}
          disabled={!deadlineDays || deadlineDays <= MINIMUM_DAYS_VALUE}
        />
        <InputNumber
          disabled={false}
          min={MINIMUM_DAYS_VALUE}
          max={MAXIMUM_DAYS_VALUE}
          value={deadlineDays}
          onChange={e => checkIfNumberIsValid(e)}
          onKeyDown={e => {
            if (e.key === '.' || e.key === '-') {
              e.preventDefault();
            }
          }}
        />

        <ModifyDaysToFollowUpButton
          operator="add"
          disabled={
            deadlineDays >= MAXIMUM_DAYS_VALUE ||
            deadlineValidationStatus === 'error'
          }
          onClick={handleButtonNumberChange}
        />
        <span> days.</span>
      </div>
      {warningState.showWarning && (
        <span
          style={{
            color: '#db0711',
            marginLeft: '120px',
          }}
        >
          Task deadline exceeds the allowed case file period.
        </span>
      )}
    </>
  );
};

export default DeadlinePredefinedTask;
