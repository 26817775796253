import { isArray, isPlainObject, last, get } from 'lodash';
import { TEXT, TEXTAREA } from '../../../../constants/fieldTypes';

import {
  symlinkPathPropertyName,
  symlinksPropertiesPropName,
  symlinkToPropertyName,
} from 'APP_ROOT/utils/create-symlink';

// key with '-' is only generated by the upload component '#-notes'
const UPLOAD_SEPARATOR = '-';

const getRepeaterKeys = (formFields, fieldKeys, parentKey) => {
  const repeater = formFields[parentKey];
  let repKeys = [];
  repeater.forEach((item, i) => {
    const itemKeys = Object.keys(item).reduce((allKeys, key) => {
      return fieldKeys.reduce((allFKeys, fKey) => {
        if (
          key === fKey ||
          (key.includes(UPLOAD_SEPARATOR) &&
            last(key.split(UPLOAD_SEPARATOR)) === fKey)
        ) {
          allFKeys.push(`${parentKey}[${i}].${key}`);
        }
        return allFKeys;
      }, allKeys);
    }, []);
    repKeys = repKeys.concat(itemKeys);
  });
  return repKeys;
};

const reduceErrors = (values, errors, fieldsByKey) => (result, key) => {
  let value;
  // first entry of the array could be empty
  if (
    isArray(errors[key]) &&
    isPlainObject(errors[key].find(e => e !== undefined))
  ) {
    result[key] = errors[key].map((rep, i) => {
      return Object.keys(rep).reduce(
        reduceErrors(values[key][i], errors[key][i], fieldsByKey),
        {}
      );
    });
  } else {
    const _key = key.includes(UPLOAD_SEPARATOR)
      ? last(key.split(UPLOAD_SEPARATOR))
      : key;
    const defaultValue = [TEXT, TEXTAREA].includes(fieldsByKey[_key].field_type)
      ? ''
      : undefined;
    value = values[key] || defaultValue;
    result[key] = { value, ...errors[key] };
  }
  return result;
};

export const getFormFieldKeys = (formFields, fieldKeys) => {
  return Object.keys(formFields).reduce((toVerify, key) => {
    // check if the key is a repeater
    if (isArray(formFields[key]) && isPlainObject(formFields[key][0])) {
      const repKeys = getRepeaterKeys(formFields, fieldKeys, key);
      // save repeater keys in the array to verify
      toVerify = toVerify.concat(repKeys);
    } else if (fieldKeys.find(fKey => key === fKey)) {
      toVerify.push(key);
    }
    return toVerify;
  }, []);
};

const getSymlinkRepeaterKeys = props => {
  const { parentKey, parentIndex, selectedForm } = props;
  if (!parentKey) return [];
  const { data } = selectedForm;
  const repeaterKey = `${parentKey}[${parentIndex}]`;
  const repeaterItem = get(data, repeaterKey, {});
  const __repeaterSymlinks = get(data, '__repeaterSymlinks', []);
  const symlinkProps = get(repeaterItem, symlinksPropertiesPropName, []);

  return symlinkProps.reduce(
    (list, rKey) => {
      const path = repeaterItem[rKey];
      // find the children in the symlink structure
      const children = __repeaterSymlinks
        .filter(symlink => symlink[symlinkPathPropertyName] === path)
        .map(symlink => symlink[symlinkToPropertyName]);
      // find the repeater item that belongs to the parent repeater
      const sList = children.reduce((cList, cKey) => {
        const repeater = get(data, cKey, []);
        const c = repeater
          .map((r, i) =>
            r[symlinkPathPropertyName] === path ? `${cKey}[${i}]` : null
          )
          .filter(r => r !== null);
        return cList.concat(c);
      }, []);
      return list.concat(sList);
    },
    [repeaterKey]
  );
};

const getFilteredKeys = (props, keys) => {
  const { parentKey } = props;
  const repeaterKeys = getSymlinkRepeaterKeys(props);

  // filter keys based on parentKey and parentIndex if present
  return keys.filter(
    key => !parentKey || repeaterKeys.some(rKey => key.startsWith(rKey))
  );
};

const validateSectionWrapperFields = (
  props,
  fieldKeys,
  fieldsByKey,
  next = () => {}
) => {
  const { form } = props;
  const formFields = form.getFieldsValue();

  const keys = getFormFieldKeys(formFields, fieldKeys);

  // filter keys based on parentKey and parentIndex if present
  const filteredKeys = getFilteredKeys(props, keys);

  // trigger form field validation
  form.validateFields(filteredKeys, (errors, values) => {
    // similar to the on submit process, for conditional display fields, we have
    // errors that weren't detected in the form validation method, we need to tell
    // the form about them, this way the form will show up the error messages.
    // First create the object with the field values and errors
    const keys = Object.keys(errors || {});
    const allFields = keys.reduce(
      reduceErrors(values, errors, fieldsByKey),
      {}
    );
    // now update the form fields
    form.setFields(allFields);

    // using setTimeout to avoid a race condition with the
    // repeater rendering and the modal creation, without
    // the timeout the modal is not displayed the first time
    // for CTR in repeaters
    setTimeout(() => next(errors), 300);
  });
};

export default validateSectionWrapperFields;
