import { get } from 'lodash';

const getContributorAssignedSection = ({
  contributorSectionId,
  selectedForm = {},
  parentKey,
  parentIndex,
}) => {
  const __assignedSections = get(
    selectedForm,
    ['data', '__assignedSections'],
    []
  );

  const sectionId = get(
    selectedForm,
    ['data', parentKey, parentIndex, parentKey ? 'id' : 'sectionId'],
    contributorSectionId
  );

  return (
    __assignedSections.find(v =>
      parentKey ? v.repeaterItemId === sectionId : v.sectionId === sectionId
    ) || {}
  );
};

export function getSectionDetails(props) {
  const assignedSection = getContributorAssignedSection(props);
  return {
    lastSaveDate: assignedSection.lastSaveDate,
    complete: assignedSection.complete,
    sectionId: assignedSection.isRepeater
      ? assignedSection.repeaterItemId
      : assignedSection.sectionId,
  };
}

export function findActiveSection(props) {
  const {
    activeSection: { contributorSectionId, parentKey, parentIndex },
    selectedForm = {},
  } = props;
  const assignedSection = getContributorAssignedSection({
    contributorSectionId,
    selectedForm,
    parentKey,
    parentIndex,
  });
  return assignedSection.isRepeater
    ? assignedSection.repeaterItemId
    : assignedSection.sectionId;
}
