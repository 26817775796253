import React, { Component } from 'react';
import { Modal, Button, Icon, Checkbox, notification } from 'antd';
import { get, isEmpty, capitalize } from 'lodash';

import resetCrtSection from '../../../../actions/reset-ctr-section.js';
import fetchReadableStream from '../../../../containers/caseFile/utils/fetchReadableStream.js';
import { removeAssignTaskNotification } from 'APP_ROOT/components/form-viewer/forms/utils/contribute-report-notifications.js';

class UnassignAndClearCRTSection extends Component {
  constructor(props) {
    super();
    this.state = {
      clearChkbox: false,
    };
  }

  onCancel = () => {
    const { cancelUnassignUser } = this.props;
    this.setState({ clearChkbox: false });
    cancelUnassignUser();
  };

  handleError = error => {
    if (
      error.status === 500 &&
      error?.response?.body instanceof ReadableStream
    ) {
      fetchReadableStream(error.response.body, this.errorReported);
    } else {
      notification.error({
        message: 'Heads up!',
        description: error?.error || error?.message || '',
      });
    }
  };

  errorReported = ({ success, content, errors, error, message }) => {
    if (!success) {
      const errorMessage = isEmpty(errors)
        ? error || message
        : errors.map(e => capitalize(e.message)).join(', ');

      notification.error({
        message: 'Heads up!',
        description: errorMessage || '',
      });
    }
  };

  unassignAndClear = () => {
    const {
      dispatch,
      unassignUser,
      cancelUnassignUser,
      contributorSectionId,
      data,
      parentKey,
      parentIndex,
      selectedForm: {
        meta: { id },
      },
    } = this.props;
    const repeaterId = get(data, [parentKey, parentIndex, 'id']);
    dispatch(
      resetCrtSection(
        id,
        contributorSectionId,
        repeaterId,
        (error, response) => {
          if (error) {
            this.handleError(error);
            cancelUnassignUser();
          } else {
            unassignUser(false);
            data.meta.share?.shares.forEach(share => {
              share?.shareToId.forEach(shareId => {
                removeAssignTaskNotification(Number(shareId), id);
              });
            });
          }
        }
      )
    );
  };

  handleUnassign = () => {
    const { unassignUser, hideModal } = this.props;
    const { clearChkbox } = this.state;
    if (clearChkbox) {
      Modal.confirm({
        title: 'Are you sure to unassign and clear data?',
        content: 'Data cleared cannot be recovered.',
        onOk: this.unassignAndClear,
        onCancel: this.onCancel,
      });
    } else {
      unassignUser(true);
    }
    hideModal();
    this.setState({ clearChkbox: false });
  };

  onChangeChkbox = e => {
    this.setState({
      clearChkbox: e.target.checked,
    });
  };

  render = () => {
    const { visible } = this.props;
    const { clearChkbox } = this.state;
    return (
      <Modal
        visible={visible}
        title={
          <span style={{ fontWeight: '600' }}>
            <Icon type="question-circle" style={{ color: 'gold' }} />
            &nbsp; Are you sure you want to unassign this section?
          </span>
        }
        onCancel={this.onCancel}
        icon="question-circle"
        bodyStyle={{
          fontWeight: '500',
          fontSize: '14px',
        }}
        footer={[
          <Button key="back" onClick={this.onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleUnassign}>
            Unassign
          </Button>,
        ]}
      >
        <p>
          By unassigning this section from the contributor, they will lose
          access to both edit and view this report. Additionally, you have the
          option to remove any data they previously entered.
        </p>
        <br />
        <p className="text-right">
          <Checkbox checked={clearChkbox} onChange={this.onChangeChkbox}>
            <span style={{ fontSize: '14px' }}>
              Clear data when unassigning.
            </span>
          </Checkbox>
        </p>
      </Modal>
    );
  };
}

export default UnassignAndClearCRTSection;
