import { notification } from 'antd';

export const NOTIFICATION_ERROR = 'error';
export const ACTION_FETCH = 'fetch';
export const ACTION_APPEND = 'append';

export const RANGE_DATE_FILTER = 'createdAt';
export const REPORT_NUMBER_FILTER = 'reportNumber';
export const USER_FILTER = 'userId';
export const EVENT_TYPE_FILTER = 'eventTypes';

export function showError(err) {
  let message = err.message || 'API response is not ok';
  err.response
    .json()
    .then(errorJSON => {
      message = errorJSON.errors.map(error => error.message).join(', ');
    })
    .finally(() => {
      showNotification(NOTIFICATION_ERROR, 'Error', message);
    });
}

export function showNotification(type, message, description) {
  return notification[type]({ message, description });
}

export const EVENT_TYPE_LIST = [
  { label: 'Viewed', value: 'viewed' },
  { label: 'Created', value: 'created' },
  { label: 'Updated', value: 'updated' },
  { label: 'Deleted', value: 'deleted' },
  { label: 'Assigned', value: 'assigned' },
  { label: 'Reassigned', value: 'reassigned' },
  { label: 'Unassigned', value: 'unassigned' },
  { label: 'Shared', value: 'shared' },
  { label: 'Unshared', value: 'unshared' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'Reviewed', value: 'reviewed' },
];
