import { notification } from 'antd';
import configurationEndpoints from '../../../api/configuration/configurationEndpoints';

const showNotification = (type, message, description) =>
  notification[type]({ message, description });

const showError = (message = '') => {
  showNotification('error', 'Something went wrong', message);
};

const COLUMNS_KEYS = {
  casefileId: 'Case File ID',
  name: 'Title',
  description: 'Description',
  createdByFullName: 'Owner',
  createdAt: 'Created Date',
  individuals: 'Individuals',
  reasons: 'Reasons',
  outcomes: 'Outcomes',
  deadline: 'Deadline',
  status: 'Status',
};

export default {
  getKeys: () => COLUMNS_KEYS,
  saveColumns: columns => {
    const formattedColumns = Object.entries(columns).reduce((prev, curr) => {
      const [key, value] = curr;
      // Guarantee we are always saving the casefileId column
      if ((value && value.checked) || key === 'casefileId') {
        return [...prev, key];
      }
      return prev;
    }, []);
    return configurationEndpoints
      .updateCaseFileListColumnPreferences(formattedColumns)
      .then(response => {
        if (response) {
          return columns;
        }
        showError('Error saving your column preferences');
        return null;
      })
      .catch(() => {
        showError('Error saving your column preferences');
        return null;
      });
  },
  resetColumns: () => {
    const columns = [];
    return configurationEndpoints
      .updateCaseFileListColumnPreferences(columns)
      .then(response => {
        if (response) {
          return columns;
        }
        showError('Error reseting your column preferences');
        return null;
      })
      .catch(() => {
        showError('Error reseting your column preferences');
        return null;
      });
  },
};
