import React, { useState, useEffect } from 'react';
import { Icon, Row } from 'antd';

import { NewReportsAuditLogsTable } from '../pages/report-timeline-audit-logs-page.styled';
import ReportsAuditLogsFilters from './ReportsAuditLogsFilters';
import { ShowMoreButton, FooterText } from './ReportsAuditLogs.styled';

import getReportsAuditLogsTableConfig from '../pages/getReportsAuditLogsTableConfig';
import { getReportsAuditLogs } from '../../../../api/forms/auditLogsEndpoints';

import { showError, ACTION_APPEND, ACTION_FETCH } from './constants';

const ReportsAuditLogs = ({ agencyId, tenantId, timezone }) => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [filters, setFilters] = useState({ initial: true });

  const tableConfig = getReportsAuditLogsTableConfig(timezone);

  const fetchAuditLogs = action => {
    setLoading(true);
    getReportsAuditLogs(tenantId, filters, nextPage)
      .then(response => processResponse(action)(response))
      .catch(err => showError(err))
      .finally(() => setLoading(false));
  };

  const processResponse = action => response => {
    const {
      content: { auditLogs: _auditLogs, cursor },
    } = response;

    switch (action) {
      case ACTION_FETCH:
        setAuditLogs(_auditLogs);
        setNextPage(cursor);
        break;

      case ACTION_APPEND:
        setAuditLogs(prevState => [...prevState, ..._auditLogs]);
        setNextPage(cursor);
        break;

      default:
        return;
    }
  };

  const handleFilterChange = newFilters => {
    setNextPage(null);
    setFilters(newFilters);
  };

  useEffect(() => {
    fetchAuditLogs(ACTION_FETCH);
  }, []);

  useEffect(() => {
    if (!loading && !filters.initial) {
      fetchAuditLogs(ACTION_FETCH);
    }
  }, [filters]);

  return (
    <>
      <ReportsAuditLogsFilters
        loading={loading}
        agencyId={agencyId}
        handleFilterChange={handleFilterChange}
      />
      <NewReportsAuditLogsTable
        loading={loading}
        rowKey={record => record.auditId}
        columns={tableConfig}
        dataSource={auditLogs}
        pagination={false}
        scroll={{ x: '100%' }}
        style={{ margin: '12px 7px 0 0' }}
      />
      <Row type="flex" justify="center" style={{ width: '100%' }}>
        {nextPage ? (
          <ShowMoreButton
            data-testid="showMoreButton"
            type="link"
            disabled={loading}
            onClick={() => fetchAuditLogs(ACTION_APPEND)}
          >
            {loading ? 'Loading...' : 'Show more'}
          </ShowMoreButton>
        ) : (
          !loading && (
            <FooterText>
              <Icon type="file-text" style={{ fontSize: 48 }} />
              <span data-testid="noMoreLogsToShow">No more logs to show</span>
            </FooterText>
          )
        )}
      </Row>
    </>
  );
};

export default ReportsAuditLogs;
