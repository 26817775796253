import {
  route,
  fetchRequest,
  REACT_APP_FORMS_SERVICE_URI,
  genOptions,
} from '../../utils/request';
import urlBuilder from '../../utils/url-builder';
import storage from '../../utils/storage';
import { omit } from 'lodash';
import { unEscapeInput } from '../../utils/request';

const encodeFilters = (filters = {}) => {
  let _filters = {};
  Object.entries(filters).forEach(([key, value]) => {
    if (value) _filters[key] = encodeURIComponent(value);
  });
  return _filters;
};

export const getAuditLogs = (tenantId, params = {}) => {
  const filteredParams = params.eventType ? params : omit(params, 'eventType');
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}audits/tenants/:tenantId`,
      { tenantId },
      {
        ...filteredParams,
      }
    )
  );
  const options = genOptions('GET', null, `Bearer ${storage.get('token')}`);
  return fetchRequest(url, options);
};

export const getReportsAuditLogs = async (
  tenantId,
  filters = {},
  pageCursor
) => {
  const _filters = encodeFilters(filters);
  _filters.sort = '-timestamp';
  _filters['page.limit'] = 10;
  if (pageCursor) _filters['page.cursor'] = pageCursor;

  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI.replace(
        'v1',
        'v2'
      )}audits/tenants/:tenantId`,
      { tenantId },
      {
        ..._filters,
      }
    )
  );
  const options = genOptions('GET', null, `Bearer ${storage.get('token')}`);
  const response = await fetchRequest(url, options);
  return unEscapeInput(response);
};
