import styled from 'styled-components';
import { Button } from 'antd';

const ShowMoreButton = styled(Button)`
  margin: 16px 0;
  width: 175px;
  align-self: center;
  justify-self: center;
  gap: 8px;
`;

const FooterText = styled.span`
  display: flex;
  color: gray;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin: 16px 0;
  gap: 8px;
`;

export { ShowMoreButton, FooterText };
