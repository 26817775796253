import React, { useEffect, useState } from 'react';
import { Button, Divider, Modal, Typography, Icon } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

import { StyledTable } from '../../AgencyCasefiles.styled';
import getOutcomesTableConfig from './getOutcomesTableConfig';

import { PageWrapper, Content, Header, Title } from './Outcomes.styled';
import AddOutcomeModal from './AddOutcomeModal';
import caseFileEndpoints from '../../../../../api/caseFileEndpoints/caseFileEndpoints';
import {
  NOTIFICATION_ERROR,
  showError,
  showSuccess,
  showNotification,
} from '../../constants';

const { confirm } = Modal;
const { Text } = Typography;

function Outcomes(props) {
  const [outcomes, setOutcomes] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { timezone } = props;
  const tableConfig = getOutcomesTableConfig(timezone, handleDeleteOutcome);

  useEffect(() => {
    const { casefileType } = props;
    setLoadingData(true);
    caseFileEndpoints
      .getCasefileTypeOutcomes(casefileType.key)
      .then(response => {
        if (response) {
          const outcomes = response.content.map(outcome => ({
            key: outcome.id,
            title: htmlUnescape(outcome.label),
            createdAt: outcome.createdAt,
          }));
          setOutcomes(outcomes);
        }
      })
      .catch(err => showError(err))
      .finally(() => setLoadingData(false));
  }, []);

  function handleToggleModal() {
    setShowModal(prevState => !prevState);
  }

  function handleDeleteOutcome(data) {
    const { casefileType } = props;
    confirm({
      autoFocusButton: null,
      title: (
        <Text
          strong
          style={{
            color: '#121212',
            fontSize: '16px',
          }}
        >
          Are you sure you want to delete this outcome?
        </Text>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      width: 600,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
      onOk: () => {
        setLoadingData(true);
        caseFileEndpoints
          .archiveOutcome(casefileType.key, data.key)
          .then(response => {
            if (response) {
              setShowModal(false);
              showSuccess('Outcome removed successfully');
              const updatedOutcomes = outcomes.filter(
                task => task.key !== data.key
              );
              setOutcomes(updatedOutcomes);
            }
          })
          .catch(err => showError(err))
          .finally(() => setLoadingData(false));
      },
      onCancel: () => {},
    });
  }

  function handleSaveNewOutcome(label) {
    const { casefileType } = props;
    const outcomeExists = outcomes.find(outcome => outcome.title === label);

    if (outcomeExists) {
      const message = `The outcome with label '${label}' already exists.`;
      showNotification(NOTIFICATION_ERROR, 'Error', message);
    } else {
      setLoadingData(true);
      caseFileEndpoints
        .createCasefileTypeOutcome(casefileType.key, {
          label,
        })
        .then(response => {
          if (response) {
            setShowModal(false);
            showSuccess(`Outcome '${label}' created successfully`);
            const newOutcome = {
              key: response.content.id,
              title: htmlUnescape(response.content.label),
              createdAt: response.content.createdAt,
            };
            setOutcomes(prevState => [newOutcome, ...prevState]);
          }
        })
        .catch(err => showError(err))
        .finally(() => setLoadingData(false));
    }
  }

  return (
    <PageWrapper>
      <Content>
        <Header>
          <Title>Outcomes</Title>
          <Button
            type="primary"
            onClick={handleToggleModal}
            disabled={loadingData}
          >
            Add Outcome
          </Button>
        </Header>
        <Divider />
        <StyledTable
          columns={tableConfig}
          dataSource={outcomes}
          loading={loadingData}
        />
      </Content>
      <AddOutcomeModal
        onOk={handleSaveNewOutcome}
        onCancel={handleToggleModal}
        visible={showModal}
      />
    </PageWrapper>
  );
}

export default Outcomes;
