import ServiceClient from '../serviceClient';
import { isEmpty } from 'lodash';
import { escapeInput, unEscapeInput, fetchRequest } from '../../utils/request';
import {
  formatDateString,
  BENCHMARK_DATE_FORMAT_HYPHEN,
} from '../../utils/parse-date';
import { unescape as htmlUnescape } from 'html-escaper';
class caseFileEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'https://localhost:3011';
    super(process.env.REACT_APP_CASEFILE_SERVICE_URI || DEV_URI, 'api/csf/v1');
  }

  encodeFilters = (filters = {}) => {
    let _filters = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value) _filters[key] = encodeURIComponent(value);
    });
    return _filters;
  };

  createCasefile = async (tenantId, userId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/tenants/:tenantId/users/:userId'),
        method: 'POST',
      },
      { tenantId, userId },
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  addReportToCasefile = (casefileId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/report/'),
        method: 'POST',
      },
      {},
      { casefileId },
      payload
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  addNoteToCasefile = async (casefileId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/notes/'),
        method: 'POST',
      },
      {},
      { casefileId },
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  removeNoteToCasefile = noteId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/notes/:noteId'),
        method: 'DELETE',
      },
      { noteId },
      {},
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  updateNoteToCasefile = async (noteId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/notes/'),
        method: 'PATCH',
      },
      {},
      { noteId },
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  updateCasefile = (casefileId, payload, ownerChanged) => {
    const formattedPayload = { ...payload };
    if (formattedPayload.deadline) {
      const formattedDeadline = formatDateString(
        formattedPayload.deadline,
        BENCHMARK_DATE_FORMAT_HYPHEN
      );
      formattedPayload.deadline = formattedDeadline;
    }

    if (ownerChanged) {
      formattedPayload.owner = payload.createdByFullName;
      delete formattedPayload.createdByFullName;
    }

    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId'),
        method: 'PATCH',
      },
      { casefileId },
      {},
      escapeInput(formattedPayload)
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  addDocumentCaseFile = (
    casefileId,
    fileName,
    fileType,
    fileSize,
    description
  ) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/document/upload/request-url'),
        method: 'POST',
      },
      {},
      {
        casefileId,
      },
      {
        fileName,
        fileType,
        fileSize,
        description: encodeURIComponent(htmlUnescape(description)),
      }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  deleteDocumentCaseFile = (casefileId, documentId) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId/document/:documentId'),
        method: 'DELETE',
      },
      { casefileId, documentId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  updateDocument = (casefileId, documentId, description) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefiles/:casefileId/document/:documentId'),
        method: 'PATCH',
      },
      {
        casefileId,
        documentId,
      },
      {},
      escapeInput({ description })
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getCasefileList = (
    userId,
    pageNumber = 1,
    pageSize = 10,
    sortBy,
    sortDirection,
    filters = {}
  ) => {
    const params = { pageNumber, pageSize };
    if (!isEmpty(sortBy)) {
      // if sent them with undefined or null backend fails
      // if not sent, backend set as default sortBy=name and sortDirection=ASC
      params.sortBy = sortBy;
      params.sortDirection = sortDirection;
    }
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/users/:userId'),
        method: 'POST',
      },
      { userId },
      params,
      escapeInput(filters)
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getCasefile = async casefileId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId'),
        method: 'GET',
      },
      { casefileId },
      {}
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCategories = () => {
    const fetchParams = this.buildFetchParams({
      path: this.withApiPrefix('/type/categories'),
      method: 'GET',
    });
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getCaseFileTypesCategories = async () => {
    const fetchParams = this.buildFetchParams({
      path: this.withApiPrefix('/category'),
      method: 'GET',
    });
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  saveCaseFleType = async payload => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type'),
        method: 'POST',
      },
      {},
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCasefileTypes = async tenantId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/tenants/:tenantId'),
        method: 'GET',
      },
      { tenantId },
      {}
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  deleteCasefileType = caseFileTypeId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:caseFileTypeId'),
        method: 'DELETE',
      },
      { caseFileTypeId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  saveCasefileTypeDeadline = (casefileTypeId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId'),
        method: 'PATCH',
      },
      { casefileTypeId },
      {},
      payload
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getPredefinedTaskLists = async casefileTypeId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/predefinedTasks'),
        method: 'GET',
      },
      { casefileTypeId },
      {}
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  addPredefinedTaskToCasefileType = async (casefileTypeId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/predefinedTasks'),
        method: 'POST',
      },
      { casefileTypeId },
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  archivePredefinedTask = (casefileTypeId, predefinedTaskId) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/type/:casefileTypeId/predefinedTasks/:predefinedTaskId'
        ),
        method: 'DELETE',
      },
      { casefileTypeId, predefinedTaskId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  shareCaseFile = (casefileId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId/share'),
        method: 'POST',
      },
      { casefileId },
      {},
      payload
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  deleteCaseFile = casefileId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId'),
        method: 'DELETE',
      },
      { casefileId },
      {},
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getDocumentUploadURL = (casefileId, description = '') => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/document'),
        method: 'POST',
      },
      {},
      { casefileId, description }
    );
    return fetchParams.URI;
  };

  getDocumentDownloadParams = (casefileId, docId) => {
    const fetchParams = this.buildFetchParams(
      {
        path:
          'api/csf/v2/casefiles/:casefileId/document/download/request-url?documentId=:docId',
        method: 'GET',
      },
      { casefileId, docId },
      {}
    );
    return fetchParams;
  };

  completeUpload = documentId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/document/upload/complete?documentId=:documentId'
        ),
        method: 'PUT',
      },
      { documentId },
      {},
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  searchReports = async (
    agencyId,
    hierarchyKey,
    organizationalUnitId,
    casefileId,
    limit,
    skip,
    keywords,
    form = '',
    data = ''
  ) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/report'),
        method: 'GET',
      },
      {},
      {
        agencyId,
        hierarchyKey,
        organizationalUnitId,
        casefileId,
        limit,
        skip,
        keywords,
        form,
        data,
      }
    );
    const searchReportsResponse = await fetchRequest(
      fetchParams.URI,
      fetchParams.request
    );
    return unEscapeInput(searchReportsResponse);
  };

  deleteReport = casefileReportId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/report/:casefileReportId'),
        method: 'DELETE',
      },
      { casefileReportId },
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getShareUsersCasefile = (
    casefileId,
    { pageSize = 50, pageNumber = 0, fullName = '' }
  ) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId/share/candidates'),
        method: 'GET',
      },
      { casefileId },
      { pageSize, pageNumber, fullName }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  addTaskToCasefile = async (casefileId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId/task'),
        method: 'POST',
      },
      { casefileId },
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCasefileTaskList = async (
    taskId,
    pageNumber = 1,
    pageSize = 5,
    sortBy,
    sortDirection
  ) => {
    const params = { pageNumber, pageSize };
    if (!isEmpty(sortBy)) {
      // if sent them with undefined or null backend fails
      // if not sent, backend set as default sortBy=name and sortDirection=ASC
      params.sortBy = sortBy;
      params.sortDirection = sortDirection;
    }
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:taskId/task'),
        method: 'GET',
      },
      { taskId },
      {}
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  updateTask = (casefileId, taskId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: 'api/csf/v2/casefiles/:casefileId/tasks/:taskId',
        method: 'PATCH',
      },
      { casefileId, taskId },
      {},
      escapeInput(payload)
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  updateTaskStatus = (casefileId, taskId, status) => {
    const fetchParams = this.buildFetchParams(
      {
        path: 'api/csf/v2/casefiles/:casefileId/tasks/:taskId/:status',
        method: 'POST',
      },
      { casefileId, taskId, status },
      {},
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  deleteTask = (casefileId, taskId, executionId) => {
    const fetchParams = this.buildFetchParams(
      {
        path:
          'api/csf/v2/casefiles/:casefileId/tasks/:taskId/execution/:executionId',
        method: 'DELETE',
      },
      { casefileId, taskId, executionId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getPredefinedTasks = async casefileId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId/predefinedTasks'),
        method: 'GET',
      },
      { casefileId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCasefileTypeReasons = async casefileTypeId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/reasons'),
        method: 'GET',
      },
      { casefileTypeId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCasefileTypeReasonsWithDeletedReasons = async (
    casefileTypeId,
    caseFileId
  ) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/type/:casefileTypeId/casefile/:caseFileId/reasons'
        ),
        method: 'GET',
      },
      { casefileTypeId, caseFileId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  createCasefileTypeReason = async (casefileTypeId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/reasons'),
        method: 'POST',
      },
      { casefileTypeId },
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  archiveReason = (casefileTypeId, reasonId) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/reasons/:reasonId'),
        method: 'DELETE',
      },
      { casefileTypeId, reasonId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getCasefileTypeOutcomes = async casefileTypeId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/outcomes'),
        method: 'GET',
      },
      { casefileTypeId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCasefileTypeOutcomesWithDeletedOutcomes = async (
    casefileTypeId,
    caseFileId
  ) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/type/:casefileTypeId/casefile/:caseFileId/outcomes'
        ),
        method: 'GET',
      },
      { casefileTypeId, caseFileId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  createCasefileTypeOutcome = async (casefileTypeId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/outcomes'),
        method: 'POST',
      },
      { casefileTypeId },
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  archiveOutcome = (casefileTypeId, outcomeId) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/outcomes/:outcomeId'),
        method: 'DELETE',
      },
      { casefileTypeId, outcomeId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getCasefileTypeStatuses = async casefileTypeId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/statuses'),
        method: 'GET',
      },
      { casefileTypeId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  updateStatusesOrder = async (casefileTypeId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/statuses/order'),
        method: 'PATCH',
      },
      { casefileTypeId },
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCasefileTypeStatusesWithDeletedStatuses = async (
    casefileTypeId,
    casefileId
  ) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/type/:casefileTypeId/casefile/:casefileId/statuses'
        ),
        method: 'GET',
      },
      { casefileTypeId, casefileId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  createCasefileTypeStatus = async (casefileTypeId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/statuses'),
        method: 'POST',
      },
      { casefileTypeId },
      {},
      escapeInput(payload)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  archiveStatus = (casefileTypeId, statusId) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/type/:casefileTypeId/statuses/:statusId'),
        method: 'DELETE',
      },
      { casefileTypeId, statusId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  getCasefileStatistics = async (integrationId, filters = {}) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/users/:integrationId/statistics'),
        method: 'POST',
      },
      { integrationId },
      {},
      escapeInput(filters)
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getStatusListDashboard = async tenantId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/casefile/tenants/:tenantId/dashboard/status'
        ),
        method: 'GET',
      },
      { tenantId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getReasonsListDashboard = async tenantId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/casefile/tenants/:tenantId/dashboard/reasons'
        ),
        method: 'GET',
      },
      { tenantId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getOutcomesListDashboard = async tenantId => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/casefile/tenants/:tenantId/dashboard/outcomes'
        ),
        method: 'GET',
      },
      { tenantId }
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getAuditLogs = async (filters = {}, pageCursor) => {
    const _filters = this.encodeFilters(filters);
    _filters.sort = '-timestamp';
    _filters['page.limit'] = 10;
    if (pageCursor) _filters['page.cursor'] = pageCursor;

    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/audit-logs'),
        method: 'GET',
      },
      {},
      _filters
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    return unEscapeInput(response);
  };

  getCasefileOwnerCandidatesList = (casefileId, fullName = null) => {
    const payload = {
      pageNumber: 1,
      pageSize: 20,
    };

    if (fullName) {
      payload.fullName = fullName;
    }

    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/casefile/:casefileId/owner/candidates'),
        method: 'GET',
      },
      { casefileId },
      escapeInput(payload)
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };
}

export default new caseFileEndpoints();
